import StyledLink from '../common/StyledLink';
import SelectProjectDialog from './SelectProjectDialog';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../store/root-state';
import { useHistory } from 'react-router-dom';
import { Project } from '../../store/current-user/types';
import { projectCacheStorage } from '../../utils/cache/project-cache-storage';

type Props = { to: string, children?: React.ReactNode }

function RequiredProjectLink({ to, children }: Props) {
  const history = useHistory();
  const selectedProject = useSelector(selectors.selectedProject);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback((event?: React.MouseEvent) => {
    if (Object.keys(selectedProject).length !== 0) {
      return;
    }

    event?.preventDefault();
    setOpen(true);
  }, [selectedProject])

  const onClose = useCallback((project?: Project) => {
    setOpen(false);

    if (!project) {
      return;
    }

    projectCacheStorage.storeProject(project);
    history.push(to);
  }, []);

  return (
    <>
      <StyledLink onClick={handleClick} to={to}>{children}</StyledLink>

      <SelectProjectDialog onClose={onClose} open={open}/>
    </>
  );
}

export default RequiredProjectLink;
