import { combineReducers } from 'redux';
import { currentUserReducer } from './current-user/reducers';
import { messageStatisticReducer } from './message-statistic/reducers';
import { errorsReducers } from './errors/reducers';
import { snackbarReducers } from './clipboard/reducers';

export const rootReducer = combineReducers({
	currentUser: currentUserReducer,
	messageStatistic: messageStatisticReducer,
	error: errorsReducers,
	snackbar: snackbarReducers
})

export type RootState = ReturnType<typeof rootReducer>

export const selectors = {
	currentUser: (s: RootState) => s.currentUser.user,
	projects: (s: RootState) => s.currentUser.projects,
	countries: (s: RootState) => s.currentUser.countries,
	selectedProject: (s: RootState) => s.currentUser.selectedProject,
	lastError: (s: RootState) => s.error.error,
	snackbar: (s: RootState) => s.snackbar.data,
	messageStatistic: {
		isLoading: (s: RootState) => s.messageStatistic.isLoading,
		filters: (s: RootState) => s.messageStatistic.filters,
		stats: (s: RootState) => s.messageStatistic.stats
	}
}

