import { SemySmsChannelOutput } from '../../../../utils/api/channels.types';
import { Devices, VpnKeyOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import IndividualEditableFields from '../editable/IndividualEditableFields';
import { channelsApi } from '../../../../utils/api/channels';
import { BaseDetailProps } from '../editable/EditableFields';

type Props = BaseDetailProps & {
  channel?: SemySmsChannelOutput
};

function SemySmsDetails({ channel, emitter, acceptFn, editMode }: Props) {
  const [token, setToken] = useState(channel?.token || '');
  const [deviceId, setDeviceId] = useState(channel?.deviceId || '');

  const [details, setDetails] = useState([
    { primary: token, secondary: 'Токен', icon: <VpnKeyOutlined/>, copyable: true, setValue: setToken },
    { primary: deviceId, secondary: 'ID устройства', icon: <Devices/>, copyable: true, setValue: setDeviceId }
  ]);

  useEffect(() => {
    const accept = () => {
      const request = (input: any) => !channel
        ? channelsApi.createSemySmsChannel(input)
        : new Promise(() => console.log('edit', input));

      acceptFn({ token, deviceId }, request);
    };

    emitter.addListener('accept', accept);

    return () => {
      emitter.removeListener('accept', accept);
    }
  });

  useEffect(() => {
    const reset = () => {
      setToken(channel?.token || '');
      setDeviceId(channel?.deviceId || '');
    };

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  });

  useEffect(() => {
    setDetails([
      { ...details[0], primary: token },
      { ...details[1], primary: deviceId }
    ])
  }, [token, deviceId]);

  return (
    <IndividualEditableFields editMode={editMode} details={details}/>
  );
}

export default SemySmsDetails;
