import { BaseDetailProps } from "../editable/EditableFields";
import { SmgHttpChannelOutput } from "../../../../utils/api/channels.types";
import { useEffect, useState } from "react";
import { channelsApi } from "../../../../utils/api/channels";
import { AccountCircleOutlined, DeviceHub, LinearScale, LockOutlined } from "@material-ui/icons";
import IndividualEditableFields from "../editable/IndividualEditableFields";

type Props = BaseDetailProps & {
	channel?: SmgHttpChannelOutput
};

function SmgHttpDetails({ channel, emitter, acceptFn, editMode }: Props) {
	const [url, setUrl] = useState(channel?.url ?? '');
	const [port, setPort] = useState(channel?.port ?? 1);
	const [login, setLogin] = useState(channel?.login ?? '');
	const [password, setPassword] = useState(channel?.password ?? '');

	const [details, setDetails] = useState([
		{ primary: url, secondary: 'Host', icon: <DeviceHub/>, copyable: true, setValue: setUrl },
		{ primary: String(port), secondary: 'ID шлюза', icon: <LinearScale/>, setValue: setPort },
		{ primary: login, secondary: 'Логин', icon: <AccountCircleOutlined/>, setValue: setLogin },
		{ primary: password, secondary: 'Пароль', icon: <LockOutlined/>, visibleMode: true, setValue: setPassword },
	]);

	useEffect(() => {
		const accept = () => {
			const request = (input: any) => !channel
				? channelsApi.createSmgHttpChannel(input)
				: new Promise(() => console.log('create', input));

			acceptFn({ url, port, login, password }, request);
		};

		emitter.addListener('accept', accept);

		return () => {
			emitter.removeListener('accept', accept);
		}
	});

	useEffect(() => {
		const reset = () => {
			setUrl(channel?.url ?? '');
			setPort(channel?.port ?? 1);
			setLogin(channel?.login ?? '');
			setPassword(channel?.password ?? '');
		};

		emitter.addListener('reset', reset);

		return () => {
			emitter.removeListener('reset', reset);
		}
	});

	useEffect(() => {
		setDetails([
			{ ...details[0], primary: url },
			{ ...details[1], primary: String(port) },
			{ ...details[2], primary: login },
			{ ...details[3], primary: password }
		])
	}, [url, port, login, password]);

	return (
		<IndividualEditableFields editMode={editMode} details={details}/>
	);
}

export default SmgHttpDetails;
