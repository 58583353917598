import FilterWrapper from '../../common/FilterWrapper';
import React, { useEffect, useState } from 'react';
import { messagesApi } from '../../../utils/api/messages';
import {
  Button, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Switch, Typography
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { dynamicFieldsCacheStore } from '../../../utils/cache/dynamic-fields-cache-storage';

type Props = { open: boolean, backdropClick: () => void }

function DynamicFieldsSettings({ open, backdropClick }: Props) {
  const cached = dynamicFieldsCacheStore.getFields();

  const [dynamicKeys, setDynamicKeys] = useState(Array<string>());
  const [selected, setSelected] = useState(cached);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
      ? [...selected, event.target.name]
      : selected.filter(s => s !== event.target.name);

    setSelected(newValue);
  };

  const accept = () => {
    dynamicFieldsCacheStore.storeDynamicFields(selected);
    backdropClick();
  }

  const reset = () => {
    dynamicFieldsCacheStore.clear();
    setSelected([]);
  }

  useEffect(() => {
    messagesApi.getDynamicKeys().then(setDynamicKeys);
  }, [])

  const noDynamicKeys = (
    <Typography align='center' color='secondary' variant='h6'>Нет данных</Typography>
  );

  const renderDynamicKeys = (
    <>
      <List>
        {dynamicKeys.map(k => (
          <ListItem key={k}>
            <ListItemText primary={k}/>
            <ListItemSecondaryAction>
              <Switch onChange={handleChange} name={k} checked={selected.includes(k)} color="primary"/>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <div style={{ display: 'flex', justifyContent: 'center', gridGap: '10px' }}>
        <Button onClick={reset}
                size="small"
                variant="outlined"
                color="secondary">
          Сбросить
        </Button>

        <Button onClick={accept}
                size="small"
                variant="contained"
                color="primary"
                startIcon={<Check/>}
                disableElevation>
          Принять
        </Button>
      </div>
    </>
  );

  return (
    <FilterWrapper open={open} backdropClick={backdropClick}>
      <Typography variant='h6' align='center'>Настройка отображения доп. данных в каждом сообщении</Typography>

      <Divider/>

      {dynamicKeys.length ? renderDynamicKeys : noDynamicKeys}
    </FilterWrapper>
  );
}

export default DynamicFieldsSettings;
