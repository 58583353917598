import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { selectors } from '../../../store/root-state';
import { useToggle } from 'react-use';
import { MessengerType } from '../../../utils/api/messages.types';

export type Message = {
  title: string,
  text: string,
  recipient: string | string[],
  messenger: MessengerType
}

type Props = {
  onAccept: (input: Message) => Promise<void>,
  onCancel: () => void,
  broadcast?: boolean
}

const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridGap: '1rem',
    minWidth: '500px'
  },
  buttons: {
    display: 'flex',
    gridGap: '1rem',
    justifyContent: 'center'
  }
});

function NewDialogForm({ onAccept, onCancel, broadcast }: Props) {
  const classes = useStyles();

  const countriesCodes = useSelector(selectors.countries).map(c => c.isoCode.toLowerCase());

  const [loading, toggleLoading] = useToggle(false);

  const [title, setTitle] = useState('');
  const [recipient, setRecipient] = useState('' as string | string[]);
  const [content, setContent] = useState('');
  const [type, setType] = useState('Sms');

  const handleRecipientsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value.split('\n').map(s => s.trim());
    setRecipient(str);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value);
  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value);
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => setType(event.target.value);

  const invalidForm = !recipient.length || !content.length;

  const accept = () => {
    toggleLoading();

    onAccept({ text: content, messenger: type as MessengerType, recipient: recipient, title })
      .then(() => toggleLoading())
      .then(() => onCancel())
  }

  return (
    <div className={classes.grid}>
      {broadcast && <TextField onChange={handleTitleChange} variant="outlined" label="Название"/>}

      {broadcast
        ? <TextField onChange={handleRecipientsChange}
                     variant="outlined"
                     label="Номера телефонов (каждый на новой строке)"
                     rowsMax={5}
                     rows={3}
                     multiline/>
        : <PhoneInput onlyCountries={countriesCodes}
                      country="ru"
                      onChange={setRecipient}
                      value={recipient as string}
                      specialLabel="Номер получателя"
                      inputStyle={{ width: '100%' }}
        />
      }

      <TextField onChange={handleContentChange} variant="outlined" label="Текст" rowsMax={5} rows={3} multiline/>
      <FormControl>
        <FormLabel>Мессенджер:</FormLabel>
        <RadioGroup value={type} onChange={handleTypeChange} row>
          <FormControlLabel value="Sms" control={<Radio/>} label="Sms"/>
          <FormControlLabel value="WhatsApp" control={<Radio/>} label="WhatsApp"/>
        </RadioGroup>
      </FormControl>


      <div className={classes.buttons}>
        <Button onClick={onCancel} disabled={loading} color="secondary">Отмена</Button>
        <Button onClick={accept}
                variant="outlined"
                color="primary"
                disabled={invalidForm || loading}
                endIcon={!loading
                  ? <Send/>
                  : <CircularProgress size={24}/>
                }>
          Отправить
        </Button>
      </div>
    </div>
  );
}

export default NewDialogForm;
