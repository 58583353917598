import { FormControl, FormHelperText, InputAdornment, makeStyles, OutlinedInput } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  field: {
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  formControl: {
    width: '100%',

    '& *': {
      transition: '.3s'
    }
  }
}));

type Props = {
  visible: boolean,
  value: string,
  setValue?: (value: string) => void,
  icon: React.ReactNode,
  label: string,
  editMode: boolean
};

function EditableInput({ value, setValue, visible, icon, label, editMode }: Props) {
  const classes = useStyles();

  return (
    <FormControl className={`${classes.formControl} ${editMode ? classes.field : ''}`}
                 style={{ pointerEvents: editMode ? 'inherit' : 'none' }}
                 size="small">
      <OutlinedInput type={visible ? 'text' : 'password'}
                     value={value}
                     onChange={e => setValue?.(e.target.value)}
                     startAdornment={
                       icon && <InputAdornment position="start">{icon}</InputAdornment>
                     }
      />
      <FormHelperText>{label}</FormHelperText>
    </FormControl>
  );
}

export default EditableInput;
