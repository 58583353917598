import React from 'react';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { renderDate } from '../../../utils/functions';
import ChannelTable from './ChannelStatisticTable';
import { HeadCell } from '../../../utils/api/common.types';
import { AccessTime, CallMade, CallReceived, ExpandLess, ExpandMore, Loop, Refresh } from '@material-ui/icons';
import { Statistic } from '../../../utils/api/statistic.types';

type TableProps = { countryStatistic: Statistic.CountryStatisticDto[], refresh: () => void };
type RowProps = { countryStat: Statistic.CountryStatisticDto };

const useStyles = makeStyles({
  tableHeadCell: {
    display: 'flex',
    gridGap: '5px',
    alignItems: 'center',

    '& span': {
      maxWidth: '200px',
      lineHeight: '16px'
    }
  },
  countryStatRow: {
    cursor: 'pointer',
    transition: '.2s',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  }
});

const TABLE_HEAD: HeadCell[] = [
  { label: 'Страна' },
  { icon: <CallReceived fontSize="small"/>, label: 'Входящие' },
  { icon: <Loop fontSize="small"/>, label: 'В очереди' },
  { icon: <CallMade fontSize="small"/>, label: 'Исходящие' },
  { icon: <CallMade fontSize="small" color="primary"/>, label: 'Успешные исходящие' },
  { icon: <AccessTime fontSize="small"/>, label: 'Последнее исходящее' },
  { icon: <AccessTime fontSize="small" color="primary"/>, label: 'Последнее успешное исходящее' }
];

function CountryStatRow({ countryStat }: RowProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} className={classes.countryStatRow}>
        <TableCell>
          <div style={{ display: 'flex', gridGap: '10px' }}>
            <Chip label={countryStat.stats.flatMap(m => m.stats).length}
                  color="primary"
                  size="small"
                  variant="outlined"
            />
            {countryStat.countryName}
          </div>
        </TableCell>
        <TableCell>{countryStat.incomingCount}</TableCell>
        <TableCell>{countryStat.pendingCount}</TableCell>
        <TableCell>{countryStat.outgoingCount}</TableCell>
        <TableCell>{countryStat.outgoingSuccessCount} ({countryStat.outgoingSuccessPercent}%)</TableCell>
        <TableCell>{renderDate(countryStat?.lastOutgoingAt)}</TableCell>
        <TableCell>{renderDate(countryStat?.lastSuccessOutgoingAt)}</TableCell>
        <TableCell>
          {open ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: 'aliceblue' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {countryStat.stats.flatMap((messengerStat, i) => (
                <ChannelTable key={i} messengerStat={messengerStat}/>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CountryStatTable({ countryStatistic, refresh }: TableProps) {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_HEAD.map(t => (
            <TableCell key={t.label}>
              <div className={classes.tableHeadCell}>
                {t?.icon}
                <span>{t?.label}</span>
              </div>
            </TableCell>
          ))}

          <TableCell>
            <Tooltip title="Перезагрузить">
              <IconButton onClick={refresh} color="primary">
                <Refresh fontSize="small"/>
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {countryStatistic.map(cs => (
          <CountryStatRow key={cs.countryId} countryStat={cs}/>
        ))}
      </TableBody>
    </Table>
  )
}

export default CountryStatTable
