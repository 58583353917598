import { SmppSmsChannelOutput } from '../../../../utils/api/channels.types';
import { AccountCircleOutlined, DeviceHub, Http, LinearScale, LockOutlined, PhoneIphone } from '@material-ui/icons';
import IndividualEditableFields, { DetailsData } from '../editable/IndividualEditableFields';
import { useEffect, useState } from 'react';
import { channelsApi } from '../../../../utils/api/channels';
import { BaseDetailProps } from '../editable/EditableFields';

type Props = BaseDetailProps & {
  channel?: SmppSmsChannelOutput
}

function SmppSmsDetails({ channel, acceptFn, emitter, editMode }: Props) {
  const [login, setLogin] = useState(channel?.login || '');
  const [password, setPassword] = useState(channel?.password || '');
  const [host, setHost] = useState(channel?.host || '');
  const [port, setPort] = useState(channel?.port || 0);
  const [line, setLine] = useState(channel?.line || 0);
  const [phoneNumber, setPhoneNumber] = useState(channel?.phoneNumber || '');

  const [details, setDetails] = useState<DetailsData>([
    { primary: login, secondary: 'Логин', icon: <AccountCircleOutlined/>, setValue: setLogin },
    { primary: password, secondary: 'Пароль', icon: <LockOutlined/>, visibleMode: true, setValue: setPassword },
    { primary: host, secondary: 'Хост', icon: <DeviceHub/>, setValue: setHost },
    { primary: port.toString(), secondary: 'Порт', icon: <Http/>, setValue: setPort },
    { primary: line.toString(), secondary: 'Номер канала', icon: <LinearScale/>, setValue: setLine },
    { primary: phoneNumber, secondary: 'Номер телефона', icon: <PhoneIphone/>, setValue: setPhoneNumber }
  ]);

  useEffect(() => {
    const accept = () => {
      const request = (input: any) => !channel
        ? channelsApi.createSmppSmsChannel(input)
        : new Promise(() => console.log('edit', input));

      acceptFn({ login, password, host, port, line, phoneNumber }, request);
    };

    emitter.addListener('accept', accept);

    return () => {
      emitter.removeListener('accept', accept);
    }
  });

  useEffect(() => {
    const reset = () => {
      setLogin(channel?.login || '');
      setPassword(channel?.password || '');
      setHost(channel?.host || '');
      setPort(channel?.port || 0);
      setLine(channel?.line || 0);
      setPhoneNumber(channel?.phoneNumber || '');
    };

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  });

  useEffect(() => {
    setDetails([
      { ...details[0], primary: login },
      { ...details[1], primary: password },
      { ...details[2], primary: host },
      { ...details[3], primary: port.toString() },
      { ...details[4], primary: line.toString() },
      { ...details[5], primary: phoneNumber }
    ])
  }, [login, password, host, port, line, phoneNumber]);

  return (
    <IndividualEditableFields editMode={editMode} details={details}/>
  );
}

export default SmppSmsDetails;
