import { Dialog, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { AddCircleOutline, Check, Close, Edit } from '@material-ui/icons';
import TextDivider from '../../../common/TextDivider';
import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { ChannelOutput } from '../../../../utils/api/channels.types';
import EditableFields from './EditableFields';
import { EventEmitter } from 'events';
import CreateChannelDialog from '../CreateChannelDialog';
import { setSnackbar } from '../../../../store/clipboard/actions';
import { useDispatch } from 'react-redux';
import ComingSoonBadge from "../../../common/ComingSoonBadge";

type Props = { channel?: ChannelOutput };

const useStyles = makeStyles({
  hide: {
    transform: 'translateX(-100%)',
    opacity: '0',
    transition: '.3s'
  },
  show: {
    transform: 'translateX(0)',
    opacity: '1',
    transition: '.3s'
  },
  editButtons: {
    transition: '.3s',
    display: 'flex'
  }
});


function EditableDetails({ channel }: Props) {
  const classes = useStyles();
  const [editMode, toggleEditMode] = useToggle(false);
  const emitter = new EventEmitter();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const resetOrEdit = () => {
    editMode && emitter.emit('reset');

    toggleEditMode();
  }

  const handleAccept = () => {
    emitter.emit('accept');
    toggleEditMode();
  }

  const afterCreate = () => {
    setOpen(false);

    dispatch(setSnackbar({ open: true, text: 'Канал создан' }));
  }

  return (
    <>
      <TextDivider variant="center">
        <Tooltip title="Создать на основе текущего">
          <IconButton onClick={() => setOpen(true)} color="primary" size="small">
            <AddCircleOutline/>
          </IconButton>
        </Tooltip>

        <span style={{ margin: '0 10px' }}>Настраиваемые свойства</span>

        <div className={classes.editButtons} style={{ width: editMode ? '60px' : '30px' }}>
          <IconButton onClick={resetOrEdit} style={{ zIndex: 2 }} size="small">
            {editMode ? <Close color="error"/> : <Edit color="primary"/>}
          </IconButton>

          <IconButton size="small"
                      className={editMode ? classes.show : classes.hide}
                      onClick={handleAccept}>
            <ComingSoonBadge>
              <Check style={{ zIndex: 2 }} color="primary"/>
            </ComingSoonBadge>
          </IconButton>
        </div>
      </TextDivider>

      <EditableFields emitter={emitter} editMode={editMode} channel={channel}/>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <div style={{ padding: '1rem' }}>
          <CreateChannelDialog defaultValue={channel} afterCreate={afterCreate}/>
        </div>
      </Dialog>
    </>
  );
}

export default EditableDetails;
