import { Dialog, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../store/root-state';
import { setSelectedProject } from '../../store/current-user/actions';
import { Project } from '../../store/current-user/types';
import { ErrorOutline } from '@material-ui/icons';

type Props = { open: boolean, onClose: (project?: Project) => void }

const useStyles = makeStyles({
  dialog: {
    padding: '1rem',
    maxWidth: '500px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '10px'
  }
});

function SelectProjectDialog({ open, onClose }: Props) {
  const classes = useStyles();
  const projects = useSelector(selectors.projects);
  const dispatch = useDispatch();

  const handleListItemClick = (project: Project) => {
    dispatch(setSelectedProject(project));
    onClose(project);
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <div className={classes.dialog}>
        <div className={classes.title}>
          <ErrorOutline color="error"/>
          <Typography variant="body1">Перед переходом в данный раздел требуется выбор проекта!</Typography>
        </div>

        <List>
          {projects.map(p => (
            <ListItem key={p.id} button onClick={() => handleListItemClick(p)}>
              <ListItemText primary={p.name}/>
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
}

export default SelectProjectDialog;
