import { Divider, FormControl, FormHelperText, makeStyles, MenuItem, Select } from '@material-ui/core';
import WrappedSelect from '../../../common/WrappedSelect';
import {
  ChannelOutput, ChannelState, ChannelType, GoipHttpApiChannelOutput, RetryStrategyType, SemySmsChannelOutput,
  SmppSmsChannelOutput, TwilioChannelOutput, YeastarChannelOutput,
} from '../../../../utils/api/channels.types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../../../store/root-state';
import { MessengerType } from '../../../../utils/api/messages.types';
import GoipHttpDetails from '../switch-details/GoipHttpDetails';
import { EventEmitter } from 'events';
import SemySmsDetails from '../switch-details/SemySmsDetails';
import SmppSmsDetails from '../switch-details/SmppSmsDetails';
import TwilioDetails from '../switch-details/TwilioDetails';
import YeastarDetails from '../switch-details/YeastarDetails';
import { absurd } from "../../../../utils/functions";
import HtmlWebDetails from "../switch-details/HtmlWebDetails";
import SmgHttpDetails from "../switch-details/SmgHttpDetails";
import SmsCountryDetails from "../switch-details/SmsCountryDetails";
import GupShupDetails from "../switch-details/GupShupDetails";

export type BaseDetailProps = {
  // Первым аргументом получаем настройки из дочернего компонента
  // Вторым аргументом api метод
  acceptFn: (settings: any, request: (input: any) => Promise<any>) => void,
  emitter: EventEmitter,
  editMode: boolean
}

type Props = {
  channel?: ChannelOutput,
  editMode: boolean,
  emitter: EventEmitter,
  createMode?: boolean
};

const useStyles = makeStyles(theme => ({
  editable: {
    display: 'grid',
    alignContent: 'start',
    position: 'relative',
    padding: '5px 0',
    gridTemplateColumns: 'minmax(240px, 500px)',
    gridGap: '1rem',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  field: {
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  formControl: {
    '& *': {
      transition: '.3s'
    }
  }
}));

function EditableFields({ channel, editMode, emitter, createMode }: Props) {
  const classes = useStyles();
  const cachedCountries = useSelector(selectors.countries);

  const [countries, setCountries] = useState<string[]>(channel?.countries?.map(c => c.id) || []);
  const [retryStrategy, setRetryStrategy] = useState<RetryStrategyType>(channel?.retryStrategy || 'Normal');
  const [messengerType, setMessengerType] = useState<MessengerType>(channel?.messengerType || 'Sms');
  const [type, setType] = useState<ChannelType>(channel?.type || 'GoipHttpApi');
  const [state, setState] = useState<ChannelState>(channel?.state || 'Active');

  // Первым аргументом получаем настройки из дочернего компонента
  // Вторым аргументом api метод
  const acceptFn = (settings: any, request: (input: any) => Promise<any>) => {
    const input = {
      ...settings,
      type: channel?.type || 'GoipHttpApi',
      state: state || 'Active',
      countries,
      retryStrategy,
      messengerType
    };

    request(input);
  }

  const getChannelForm = () => {
    if (!channel) {
      switch (type) {
        case 'GoipHttpApi':
          return <GoipHttpDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>;

        case 'SemySms':
          return <SemySmsDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'SmppSms':
          return <SmppSmsDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'Twilio':
          return <TwilioDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'Yeastar':
          return <YeastarDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'HtmlWeb':
          return <HtmlWebDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'SmgHttp':
          return <SmgHttpDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'SmsCountry':
          return <SmsCountryDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'GupShup':
          return <GupShupDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>
      }

      absurd(type);
    }

    if (channel && createMode) {
      switch (type) {
        case 'GoipHttpApi':
          const goip = { ...channel, type } as GoipHttpApiChannelOutput;
          return <GoipHttpDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={goip}/>;

        case 'SemySms':
          const semy = { ...channel, type } as SemySmsChannelOutput;
          return <SemySmsDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={semy}/>

        case 'SmppSms':
          const smpp = { ...channel, type } as SmppSmsChannelOutput;
          return <SmppSmsDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={smpp}/>

        case 'Twilio':
          const twilio = { ...channel, type } as TwilioChannelOutput;
          return <TwilioDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={twilio}/>

        case 'Yeastar':
          const yeastar = { ...channel, type } as YeastarChannelOutput;
          return <YeastarDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={yeastar}/>

        case 'HtmlWeb':
          return <HtmlWebDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'SmgHttp':
          return <SmgHttpDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'SmsCountry':
          return <SmsCountryDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>

        case 'GupShup':
          return <SmsCountryDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter}/>
      }

      absurd(type);
    }

    switch (channel.type) {
      case 'GoipHttpApi':
        return <GoipHttpDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>;

      case 'SemySms':
        return <SemySmsDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'SmppSms':
        return <SmppSmsDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'Twilio':
        return <TwilioDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'Yeastar':
        return <YeastarDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'HtmlWeb':
        return <HtmlWebDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'SmgHttp':
        return <SmgHttpDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'SmsCountry':
        return <SmsCountryDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>

      case 'GupShup':
        return <GupShupDetails editMode={editMode} acceptFn={acceptFn} emitter={emitter} channel={channel}/>
    }

    absurd(channel);
  }

  useEffect(() => {
    const reset = () => {
      setCountries(channel?.countries?.map(c => c.id) || [])
      setRetryStrategy(channel?.retryStrategy || 'Normal');
      setMessengerType(channel?.messengerType || 'Sms');
      setState(channel?.state || 'Active');
      setType('GoipHttpApi');
    }

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  })

  return (
    <div style={{ overflow: 'auto', transition: '.3s' }}>
      <div className={`${classes.editable} ${editMode ? classes.field : ''}`}>
        {createMode &&
        <FormControl variant="outlined"
                     style={{ width: '100%' }}
                     size="small">
          <Select value={type}
                  onChange={e => setType(e.target.value as ChannelType)}
          >
            <MenuItem value="GoipHttpApi">GoipHttpApi</MenuItem>
            <MenuItem value="SemySms">SemySms</MenuItem>
            <MenuItem value="SmppSms">SmppSms</MenuItem>
            <MenuItem value="Twilio">Twilio</MenuItem>
            <MenuItem value="Yeastar">Yeastar</MenuItem>
            <MenuItem value="HtmlWeb">HtmlWeb</MenuItem>
            <MenuItem value="SmgHttp">SmgHttp</MenuItem>
            <MenuItem value="GupShup">GupShup</MenuItem>
          </Select>
          <FormHelperText>Тип</FormHelperText>
        </FormControl>
        }

        <FormControl variant="outlined"
                     className={`${editMode ? classes.field : ''} ${classes.formControl}`}
                     style={{ pointerEvents: editMode ? 'inherit' : 'none' }}
                     size="small">
          <WrappedSelect
            labelId=""
            values={cachedCountries}
            value={countries}
            onChange={c => setCountries(c)}
          />
          <FormHelperText>Страны</FormHelperText>
        </FormControl>

        <FormControl variant="outlined"
                     className={`${editMode ? classes.field : ''} ${classes.formControl}`}
                     style={{ pointerEvents: editMode ? 'inherit' : 'none' }}
                     size="small">
          <Select value={messengerType}
                  onChange={e => setMessengerType(e.target.value as MessengerType)}
          >
            <MenuItem value="Sms">Sms</MenuItem>
            <MenuItem value="WhatsApp">WhatsApp</MenuItem>
          </Select>
          <FormHelperText>Тип мессенджера</FormHelperText>
        </FormControl>

        <FormControl variant="outlined"
                     className={`${editMode ? classes.field : ''} ${classes.formControl}`}
                     style={{ pointerEvents: editMode ? 'inherit' : 'none' }}
                     size="small">
          <Select value={retryStrategy}
                  onChange={e => setRetryStrategy(e.target.value as RetryStrategyType)}
          >
            <MenuItem value="Normal">Стандартная</MenuItem>
            <MenuItem value="TryAnotherChannel">Пробовать другой канал</MenuItem>
          </Select>
          <FormHelperText>Стратегия</FormHelperText>
        </FormControl>

        <Divider/>

        {getChannelForm()}
      </div>
    </div>
  );
}

export default EditableFields;
