import ContactsChipList from './ContactsChipList';
import React from 'react';
import { Divider, IconButton, makeStyles, Popover } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { BroadcastingContactOutput } from '../../utils/api/broadcasts.types';

const useStyles = makeStyles({
  faq: {
    display: 'grid',
    gridGap: '1rem',
    maxWidth: '800px',
    padding: '1rem'
  },
  group: {
    display: 'grid',
    gridGap: '1rem',
    justifyContent: 'center',
    justifyItems: 'center',

    '& div': {
      cursor: 'default !important'
    }
  }
});

function ChipsFaqPopover() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const firstGroup: BroadcastingContactOutput[] = [
    { contact: 'В процессе', type: 'NotProcessedYet' } as BroadcastingContactOutput,
    { contact: 'Не может быть обработан', type: 'NotAvailableToProcess' } as BroadcastingContactOutput
  ]

  const secondGroup: BroadcastingContactOutput[] = [
    {
      contact: 'Доставлен в чат, сообщение обрабатывается',
      type: 'ProcessedWithExistedConversation',
      message: { state: 'Pending' }
    } as BroadcastingContactOutput,
    {
      contact: 'Доставлен в чат, сообщение не отправилось',
      type: 'ProcessedWithExistedConversation',
      message: { state: 'Error' }
    } as BroadcastingContactOutput,
    {
      contact: 'Доставлен в чат, сообщение отправилось',
      type: 'ProcessedWithExistedConversation',
      message: { state: 'Sent' }
    } as BroadcastingContactOutput
  ];

  const thirdGroup: BroadcastingContactOutput[] = [
    { contact: 'Доставлен в новый чат', type: 'ProcessedWithNewConversation' } as BroadcastingContactOutput
  ]

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <HelpOutline/>
      </IconButton>

      <Popover open={open}
               anchorEl={anchorEl}
               onClose={handleClose}>
        <div className={classes.faq}>
          <div className={classes.group}>
            <ContactsChipList contacts={firstGroup}/>
          </div>

          <Divider/>

          <div className={classes.group}>
            <ContactsChipList contacts={secondGroup}/>
          </div>

          <Divider/>

          <div className={classes.group}>
            <ContactsChipList contacts={thirdGroup}/>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default ChipsFaqPopover;
