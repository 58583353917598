import { FilterList } from '@material-ui/icons';
import { ButtonBase, makeStyles } from '@material-ui/core';
import React from 'react';

type Props = {
  onClick: () => void,
  backgroundColor: string,
  children?: React.ReactNode,
  notAbsolute?: boolean
}

const useStyles = makeStyles(theme => ({
  filterBtn: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-99%, -1px)'
  },
  filterBtnView: {
    padding: '10px 1rem',
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRight: 'none',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    zIndex: 3,
    borderTopRightRadius: 0
  }
}));

function FilterButton({ onClick, backgroundColor, children, notAbsolute }: Props) {
  const classes = useStyles();

  return (
    <ButtonBase onClick={onClick}
                style={{ background: backgroundColor }}
                className={`${!notAbsolute && classes.filterBtn} ${classes.filterBtnView}`}>
      {children || <FilterList/>}
    </ButtonBase>
  );
}

export default FilterButton;
