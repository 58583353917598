import { Dialog } from '@material-ui/core';
import { useCallback, useState } from 'react';
import ConversationsWrapper from './conversations/ConversationsWrapper';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { default as Conversation } from './dialog/Dialog';
import AbsoluteAddButton from './new-chat/AbsoluteAddButton';
import NewDialogForm, { Message } from './new-chat/NewDialogForm';
import { messagesApi } from '../../utils/api/messages';
import { ConversationOutput, SendMessageInput } from '../../utils/api/messages.types';
import Layout from '../Layout';

function Chats() {
  const { path } = useRouteMatch();

  const [selectedConversation, setSelectedConversation] = useState(null as ConversationOutput | null);
  const [open, setOpen] = useState(false);

  const beforeConversationsRequest = useCallback(() => setSelectedConversation(null), []);

  const accept = async (input: Message) => {
    const prettyMessage: SendMessageInput = {
      to: input.recipient as string,
      content: input.text,
      messenger: input.messenger
    }

    await messagesApi.sendMessage(prettyMessage);
    setOpen(false);
  }

  const left = (
    <ConversationsWrapper setConversation={setSelectedConversation}
                          beforeConversationsRequest={beforeConversationsRequest}
    />
  );

  const right = (
    <Switch>
      <Route path={`${path}/:dialogId`}>
        <Conversation disableInput={selectedConversation?.channel.state !== 'Active'}/>
      </Route>
    </Switch>
  );

  return (
    <>
      <Layout leftSide={left} rightSide={right}/>

      <AbsoluteAddButton onClick={() => setOpen(true)}/>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <div style={{ padding: '1rem' }}>
          <NewDialogForm onCancel={() => setOpen(false)}
                         onAccept={accept}
          />
        </div>
      </Dialog>
    </>
  )
}

export default Chats;
