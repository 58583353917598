import {
	Avatar,
	Button,
	Container,
	CssBaseline,
	LinearProgress,
	makeStyles,
	TextField,
	Typography
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { FormEvent, useCallback, useState } from 'react';
import { useToggle } from 'react-use';
import { userApi } from '../utils/api/user';
import { CurrentUser } from '../store/current-user/types';
import { userCacheStorage } from '../utils/cache/user-cache-storage';
import { setCurrentUser } from '../store/current-user/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

function Auth() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const [isLoading, toggleLoading] = useToggle(false);
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');

	const saveCurrentUser = useCallback(async (user?: CurrentUser) => {
		if (!user) {
			return;
		}

		userCacheStorage.storeUser(user);
		dispatch(setCurrentUser(user));
	}, [dispatch]);

	const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (isLoading || !login || !password) {
			return;
		}

		toggleLoading();

		try {
			await saveCurrentUser(await userApi.login({ login, password }));
		} finally {
			toggleLoading();
		}

		history.push('/message-statistic');
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Авторизация
				</Typography>
				<form className={classes.form} onSubmit={e => handleLogin(e)}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						label="Логин"
						autoFocus
						onChange={e => setLogin(e.target.value)}
						value={login}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						label="Пароль"
						type="password"
						onChange={e => setPassword(e.target.value)}
						value={password}
					/>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={isLoading}
						type="submit"
					>
						Войти
					</Button>
					{isLoading && <LinearProgress />}
				</form>
			</div>
		</Container>
	);
}

export default Auth;
