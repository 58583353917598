import * as datefns from 'date-fns';
import ru from 'date-fns/locale/ru';
import { Block, Check, Close, MailOutline, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { ChannelsState } from './api/statistic.types';

/**
 * Конвертирует строку с датой в объект, с верно выставленной timezone.
 * Если в дате не указана timezone, то такая дата считается с нулевым отступом UTC.
 *
 * @param {string} dateTimeString
 * @return {Date}
 */
export function parseDate(dateTimeString: string): Date {
  const [datetime, tzOffset] = dateTimeString.split('+');

  const toParse = tzOffset ? dateTimeString : `${datetime}+0000`;

  return new Date(toParse);
}

/**
 Форматирует дату в строку вида dd.MM.yyyy hh:mm

 @param {string} dateString
 * @param format нужный формат. 'dd.MM.yyyy HH:mm' по умолчанию
 */
export function formatDate(dateString: string | null, format = 'dd.MM.yyyy HH:mm') {
  return dateString
    ? datefns.format(parseDate(dateString), format)
    : '---';
}

/**
 * Возвращает текст вида 'n часов/дней/месяцев назад'
 *
 * @param dateString
 */
export function renderDate(dateString: string | null) {
  return dateString
    ? datefns.formatDistanceToNowStrict(parseDate(dateString), { locale: ru, addSuffix: true })
    : '---';
}

export function messageTypeIcon(type: 'Sms' | 'WhatsApp', channelState?: ChannelsState) {
  switch (type) {
    case 'Sms':
      return (<MailOutline style={{ color: channelState ? channelStateToColor(channelState) : 'inherit' }}/>);
    case 'WhatsApp':
      return (<WhatsApp style={{ color: channelState ? channelStateToColor(channelState) : 'inherit' }}/>);
  }
}

export function channelState(state: 'Active' | 'Banned' | 'Inactive') {
  switch (state) {
    case 'Active':
      return <Check style={{ color: channelStateToColor(state) }}/>;
    case 'Banned':
      return <Block style={{ color: channelStateToColor(state) }}/>;
    case 'Inactive':
      return <Close style={{ color: channelStateToColor(state) }}/>;
  }
}

const channelStateToColor = (state: ChannelsState): string => {
  switch (state) {
    case 'Active':
      return 'green';

    case 'Inactive':
      return 'gray';

    case 'Banned':
      return 'red';
  }
};

export function paramsToString(params: { [key: string]: null | undefined | string | string[] | number }): string {
  return Object.keys(params)
    .flatMap((k: string) => {
      const value = params[k];

      if (Array.isArray(value)) {
        return value.map(it => `${k}=${it}`)
      }

      return value ? `${k}=${value}` : undefined;
    })
    .filter(v => v !== undefined)
    .join('&');
}

export function absurd(value: never): never {
  throw new Error(`Absurd! Value '${JSON.stringify(value)}' should never appear here!`);
}
