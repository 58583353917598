import React, { useCallback, useEffect, useState } from 'react';
import { Divider, LinearProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { formatDate, messageTypeIcon } from '../../utils/functions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToggle } from 'react-use';
import BroadcastsFilter from './BroadcastsFilter';
import FilterButton from '../common/FilterButton';
import { useSelector } from 'react-redux';
import { selectors } from '../../store/root-state';
import { broadcastsApi } from '../../utils/api/broadcasts';
import { BroadcastOutput, BroadcastsFilterInput } from '../../utils/api/broadcasts.types';
import StyledInfiniteScroll from '../common/StyledInfiniteScroll';

type Props = { refresh: boolean };

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    height: 'inherit'
  },
  list: {
    backgroundColor: 'aliceblue',
    padding: 0,
    borderRadius: '10px',
    borderTopLeftRadius: 0
  },
  listItemText: {
    '& > span': {
      fontSize: '1.2rem'
    }
  },
  noData: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  endContent: {
    padding: '10px',
  },
  between: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

function BroadcastsList({ refresh }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const limit = 25;
  const project = useSelector(selectors.selectedProject);

  const [selectedId, setSelectedId] = useState('');
  const [broadcasts, setBroadcasts] = useState(Array<BroadcastOutput>());
  const [loading, toggleLoading] = useToggle(false);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({} as BroadcastsFilterInput);

  const filterButtonBackground = open ? 'white' : 'linear-gradient(to right, white, white, aliceblue)';

  const getMoreBroadcasts = useCallback(() => {
    broadcastsApi.getBroadcasts({ ...filter, limit, offset: broadcasts.length })
      .then(b => setBroadcasts(broadcasts.concat(b.data)))
  }, [broadcasts, filter]);

  const getBroadcasts = (filter?: BroadcastsFilterInput) => {
    toggleLoading();
    filter && setFilter(filter);

    broadcastsApi.getBroadcasts({ ...filter, limit })
      .then(b => {
        setBroadcasts(b.data);
        setTotal(b.total);
      })
      .then(() => toggleLoading())
  }

  useEffect(() => getBroadcasts(), [refresh, project])

  const accept = (filter: BroadcastsFilterInput) => {
    getBroadcasts(filter);
    setOpen(false);
  };

  const toBroadcastInfo = (id: string) => {
    history.push(`${url}/${id}`);
    setSelectedId(id);
  };

  const getText = (broadcast: BroadcastOutput) => {
    const primary = (
      <div className={classes.between}>
        <span>{broadcast.title}</span>
        <span>{broadcast.countries.map(c => c.isoCode).join(', ')}</span>
      </div>
    );

    const secondary = (
      <div className={classes.between}>
        <span>{formatDate(broadcast.createdAt)}</span>
        <span>{broadcast.contactCount} контактов</span>
      </div>
    )

    return { primary, secondary };
  }

  const scroll = (
    <StyledInfiniteScroll more={getMoreBroadcasts} dataLength={broadcasts.length} total={total}>
      <List className={classes.list}>
        {broadcasts.map(b => {
          const text = getText(b);

          return (
            <>
              <ListItem key={b.id} selected={selectedId === b.id} button onClick={() => toBroadcastInfo(b.id)}>
                <ListItemIcon>{messageTypeIcon(b.messengerType)}</ListItemIcon>

                <ListItemText className={classes.listItemText}
                              primary={text.primary}
                              secondary={text.secondary}
                />
              </ListItem>

              <Divider/>
            </>
          )
        })}
      </List>
    </StyledInfiniteScroll>
  )

  return (
    <div className={classes.wrapper}>
      <FilterButton onClick={() => setOpen(!open)} backgroundColor={filterButtonBackground}/>

      <BroadcastsFilter open={open} onAccept={accept} backdropClick={() => setOpen(false)}/>

      {!loading
        ? scroll
        : <LinearProgress style={{ display: 'grid' }} color="primary"/>
      }
    </div>
  );
}

export default BroadcastsList;
