import { Divider, LinearProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ChannelOutput } from '../../utils/api/channels.types';
import { channelsApi } from '../../utils/api/channels';
import { useHistory } from 'react-router-dom';
import { formatDate, messageTypeIcon } from '../../utils/functions';
import { useToggle } from 'react-use';
import StyledInfiniteScroll from '../common/StyledInfiniteScroll';

const useStyles = makeStyles({
  primary: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  countries: {
    maxWidth: '105px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

function ChannelsList() {
  const limit = 25;
  const history = useHistory();
  const classes = useStyles();

  const [channels, setChannels] = useState(Array<ChannelOutput>());
  const [total, setTotal] = useState(0);
  const [loading, toggleLoading] = useToggle(false);

  useEffect(() => { getInitialChannels() }, [])

  const getInitialChannels = () => {
    toggleLoading();

    channelsApi.getChannels({ limit }).then(r => {
      setChannels(r.data);
      setTotal(r.total);
      toggleLoading();
    });
  }

  const getMoreChannels = () => {
    channelsApi.getChannels({ limit, offset: channels.length })
      .then(r => setChannels(channels.concat(r.data)));
  }

  const getPrimary = (c: ChannelOutput) => (
    <span className={classes.primary}>
      <span>{c.type}</span>
      <span className={classes.countries}>{c.countries.map(country => country.isoCode).join(', ')}</span>
    </span>
  );

  return (
    <>
      {!loading
        ? <StyledInfiniteScroll more={getMoreChannels} dataLength={channels.length} total={total}>
          <List style={{ overflow: 'auto' }}>
            {channels.map(c => (
              <div key={c.id}>
                <ListItem onClick={() => history.push(`/channels/${c.id}`)} button>
                  <ListItemIcon>
                    {messageTypeIcon(c.messengerType, c.state)}
                  </ListItemIcon>
                  <ListItemText primary={getPrimary(c)} secondary={formatDate(c.createdAt)}/>
                </ListItem>

                <Divider/>
              </div>
            ))}
          </List>
        </StyledInfiniteScroll>
        : <LinearProgress style={{ display: 'grid' }} color="primary"/>
      }
    </>
  );
}

export default ChannelsList;
