import { Project } from '../../store/current-user/types';

const projectKey = 'project:selected';

function storeProject(project: Project) {
  localStorage.setItem(projectKey, JSON.stringify(project))
}

function getProject(): Project | undefined {
  const data = localStorage.getItem(projectKey);

  if (!data) {
    return;
  }

  return JSON.parse(data);
}

function clear(): void {
  localStorage.removeItem(projectKey);
}

export const projectCacheStorage = { storeProject, getProject, clear };
