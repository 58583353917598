import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LinearProgress, Typography } from '@material-ui/core';
import TextDivider from './TextDivider';

type Props = {
  more: () => void,
  dataLength: number,
  total: number,
  height?: string,
  children?: React.ReactNode
};

function StyledInfiniteScroll({ more, dataLength, total, height, children }: Props) {
  const loader = (<LinearProgress style={{ display: 'grid' }} color="primary"/>);

  const endContent = (
    <div style={{ padding: '10px' }}>
      <TextDivider variant="center">
        <Typography variant="h6">Конец списка</Typography>
      </TextDivider>
    </div>
  );

  return (
    <InfiniteScroll next={more}
                    hasMore={dataLength < total}
                    loader={loader}
                    endMessage={endContent}
                    height={height || '85vh'}
                    dataLength={dataLength}
    >
      {children}
    </InfiniteScroll>
  );
}

export default StyledInfiniteScroll;
