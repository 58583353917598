import { CurrentUserState, CurrentUserActions, Project } from './types';

const initialState: CurrentUserState = {
	user: undefined,
	countries: [],
	projects: [],
	selectedProject: {} as Project
}

export function currentUserReducer(
	state = initialState,
	action: CurrentUserActions
): CurrentUserState {
	switch (action.type) {
		case 'SET_CURRENT_USER':
			return {
				...state,
				user: action.currentUser
			};

		case 'REMOVE_CURRENT_USER':
			return {
				...state,
				user: undefined
			};

		case 'SET_PROJECTS':
			return {
				...state,
				projects: action.projects
			}

		case 'SET_COUNTRIES':
			return {
				...state,
				countries: action.countries
			}

		case 'SET_SELECTED_PROJECT':
			return {
				...state,
				selectedProject: action.project
			};

		default:
			return state;
	}
}

