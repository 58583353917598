import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import EditableFields from './editable/EditableFields';
import { EventEmitter } from 'events';
import { ChannelOutput } from '../../../utils/api/channels.types';
import ComingSoonBadge from "../../common/ComingSoonBadge";

type Props = {
  afterCreate: () => void,
  defaultValue?: ChannelOutput
};

function CreateChannelDialog({ afterCreate, defaultValue }: Props) {
  const emitter = new EventEmitter();

  useEffect(() => {
    emitter.addListener('accept', afterCreate);

    return () => {
      emitter.removeListener('accept', afterCreate);
    }
  });

  return (
    <div>
      <EditableFields channel={defaultValue} emitter={emitter} editMode={true} createMode={true}/>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ComingSoonBadge horizontal='left'>
          <Button onClick={() => emitter.emit('accept')}
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled>
            Создать
          </Button>
        </ComingSoonBadge>
      </div>
    </div>
  );
}

export default CreateChannelDialog;
