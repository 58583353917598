import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { selectors } from '../store/root-state';
import { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

function ErrorHandler() {
  const error = useSelector(selectors.lastError);

  const [open, setOpen] = useState(false);
  const [first, setFirst] = useState(true);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }

    setOpen(true);
  }, [error])

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity="error" onClose={handleClose}>{error.type}: {error.message}</Alert>
    </Snackbar>
  );
}

export default ErrorHandler;
