import { Dialog, Typography } from '@material-ui/core';
import BroadcastsList from './BroadcastsList';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import BroadcastDetails from './BroadcastDetails';
import AbsoluteAddButton from '../chats/new-chat/AbsoluteAddButton';
import NewDialogForm, { Message } from '../chats/new-chat/NewDialogForm';
import { useCallback, useState } from 'react';
import { useToggle } from 'react-use';
import { broadcastsApi } from '../../utils/api/broadcasts';
import { BroadcastInput } from '../../utils/api/broadcasts.types';
import Layout from '../Layout';

function Broadcast() {
  const { path } = useRouteMatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [refresh, toggleRefresh] = useToggle(false);

  const accept = useCallback((input: Message) => {
    const prettyMessage: BroadcastInput = {
      title: input.title,
      contacts: input.recipient as string[],
      text: input.text,
      messengerType: input.messenger
    }

    return broadcastsApi.createBroadcast(prettyMessage)
      .then(b => {
        toggleRefresh();
        history.push(`/broadcast/${b.id}`);
      });
  }, [history])

  const left = (<BroadcastsList refresh={refresh}/>);

  const right = (
    <Switch>
      <Route exact path={path}>
        <Typography style={{ textAlign: 'center', alignSelf: 'center' }}
                    variant="h5">
          Выберите рассылку
        </Typography>
      </Route>

      <Route path={`${path}/:broadcastId`}>
        <BroadcastDetails/>
      </Route>
    </Switch>
  );

  return (
    <>
      <Layout leftSide={left} rightSide={right}/>

      <AbsoluteAddButton onClick={() => setOpen(true)}/>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <div style={{ padding: '1rem' }}>
          <NewDialogForm onCancel={() => setOpen(false)}
                         onAccept={accept}
                         broadcast={true}
          />
        </div>
      </Dialog>
    </>
  );
}

export default Broadcast;
