import { Divider, List, ListItem } from "@material-ui/core";
import { DateRange } from "@material-ui/pickers";
import * as datefns from 'date-fns';

type Props = {
	onQuickFilterSelected: (dates: DateRange<Date>) => void
}

function QuickDateFilters({ onQuickFilterSelected }: Props) {
	const pickQuickFilter = (type: QuickFilters) => onQuickFilterSelected(quickFilterToDate(type));

	return (
		<List>
			<ListItem button onClick={() => pickQuickFilter('today')}>
				Сегодня
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('yesterday')}>
				Вчера
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('last 2 days')}>
				Последние 2 дня
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('last 3 days')}>
				Последние 3 дня
			</ListItem>

			<Divider />

			<ListItem button onClick={() => pickQuickFilter('week')}>
				Эта неделя
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('previous week')}>
				Прошлая неделя
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('last 2 weeks')}>
				Последние 2 недели
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('last 3 weeks')}>
				Последние 3 недели
			</ListItem>

			<Divider />

			<ListItem button onClick={() => pickQuickFilter('month')}>
				Этот месяц
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('previous month')}>
				Прошлый месяц
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('last 2 months')}>
				Последние 2 месяца
			</ListItem>
			<ListItem button onClick={() => pickQuickFilter('last 3 months')}>
				Последние 3 месяца
			</ListItem>
		</List>
	)
}

type QuickFilters =
	| 'today'
	| 'yesterday'
	| 'last 2 days'
	| 'last 3 days'
	| 'week'
	| 'previous week'
	| 'last 2 weeks'
	| 'last 3 weeks'
	| 'month'
	| 'previous month'
	| 'last 2 months'
	| 'last 3 months'

function quickFilterToDate(type: QuickFilters): DateRange<Date> {
	switch (type) {
		case "today":
			return [datefns.startOfDay(new Date()), new Date()];

		case "yesterday":
			return [datefns.startOfYesterday(), datefns.endOfYesterday()];

		case "last 2 days":
			return [datefns.startOfYesterday(), new Date()];

		case "last 3 days":
			return [datefns.sub(new Date(), { days: 2 }), new Date()]

		case "week":
			return [datefns.startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()];

		case "previous week":
			return [
				datefns.startOfWeek(datefns.sub(new Date(), { weeks: 1 }), { weekStartsOn: 1 }),
				datefns.endOfWeek(datefns.sub(new Date(), { weeks: 1 }), { weekStartsOn: 1 })
			];

		case "last 2 weeks":
			return [
				datefns.startOfWeek(datefns.sub(new Date(), { weeks: 1 }), { weekStartsOn: 1 }),
				new Date()
			];

		case "last 3 weeks":
			return [
				datefns.startOfWeek(datefns.sub(new Date(), { weeks: 2 }), { weekStartsOn: 1 }),
				new Date()
			];

		case "month":
			return [
				datefns.startOfMonth(new Date()),
				new Date()
			]

		case "previous month":
			return [
				datefns.startOfMonth(datefns.sub(new Date(), { months: 1 })),
				datefns.endOfMonth(datefns.sub(new Date(), { months: 1 }))
			];

		case "last 2 months":
			return [
				datefns.startOfMonth(datefns.sub(new Date(), { months: 1 })),
				new Date()
			]

		case "last 3 months":
			return [
				datefns.startOfMonth(datefns.sub(new Date(), { months: 2 })),
				new Date()
			]

		default:
			return [null, null];
	}
}

export default QuickDateFilters;
