import { GupShupChannelOutput, TwilioChannelOutput } from '../../../../utils/api/channels.types';
import { DeviceHub, LockOutlined, SimCardOutlined, VpnKeyOutlined } from '@material-ui/icons';
import IndividualEditableFields, { DetailsData } from '../editable/IndividualEditableFields';
import { useEffect, useState } from 'react';
import { channelsApi } from '../../../../utils/api/channels';
import { BaseDetailProps } from '../editable/EditableFields';

type Props = BaseDetailProps & {
  channel?: GupShupChannelOutput
};

function GupShupDetails({ channel, emitter, acceptFn, editMode }: Props) {
  const [app, setApp] = useState(channel?.app || '');
  const [apiKey, setApiKey] = useState(channel?.apiKey || '');
  const [sourcePhone, setSourcePhone] = useState(channel?.sourcePhone || '');

  const [details, setDetails] = useState<DetailsData>([
    { primary: app, secondary: 'App name', icon: <DeviceHub/>, setValue: setApp },
    { primary: apiKey, secondary: 'Api key', icon: <LockOutlined/>, setValue: setApiKey },
    { primary: sourcePhone, secondary: 'Номер сим-карты', icon: <SimCardOutlined/>, setValue: setSourcePhone }
  ]);

  useEffect(() => {
    const accept = () => {
      const request = (input: any) => !channel
        ? channelsApi.createChannel(input)
        : new Promise(() => console.log('edit', input));

      acceptFn({ app, apiKey, sourcePhone }, request);
    };

    emitter.addListener('accept', accept);

    return () => {
      emitter.removeListener('accept', accept);
    }
  });

  useEffect(() => {
    const reset = () => {
      setApp(channel?.app || '');
      setApiKey(channel?.apiKey || '');
      setSourcePhone(channel?.sourcePhone || '');
      console.log('123');
    };

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  });

  useEffect(() => {
    setDetails([
      { ...details[0], primary: app },
      { ...details[1], primary: apiKey },
      { ...details[2], primary: sourcePhone }
    ]);
  }, [app, apiKey, sourcePhone]);

  return (
    <IndividualEditableFields editMode={editMode} details={details}/>
  );
}

export default GupShupDetails;
