import { HeadCell } from '../../../utils/api/common.types';
import { AccessTime, CallMade, CallReceived, Loop } from '@material-ui/icons';
import { makeStyles, Popover, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { messageTypeIcon, renderDate } from '../../../utils/functions';
import ChannelAddition from './ChannelAddition';
import { MessengerType } from '../../../utils/api/messages.types';
import { Statistic } from '../../../utils/api/statistic.types';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../store/clipboard/actions';

type RowProps = {
  channelStat: Statistic.ChannelStatisticDto,
  messengerType: MessengerType,
  afterCopy: () => void
};
type TableProps = { messengerStat: Statistic.MessengerStatisticDto };

const useStyles = makeStyles({
  messageTitleCell: {
    cursor: 'pointer',
    transition: '.2s',

    '&:hover': {
      backgroundColor: '#ddecfa'
    }
  },
  messageTitle: {
    display: 'flex',
    gridGap: '5px',
    alignItems: 'center'
  }
});

const TABLE_HEAD: HeadCell[] = [
  { label: 'Шлюз' },
  { icon: <CallReceived fontSize="small"/> },
  { icon: <Loop fontSize="small"/> },
  { icon: <CallMade fontSize="small"/> },
  { icon: <CallMade fontSize="small" color="primary"/> },
  { icon: <AccessTime fontSize="small"/> },
  { icon: <AccessTime fontSize="small" color="primary"/> }
];

function ChannelStatRow({ channelStat, messengerType, afterCopy }: RowProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <TableRow>
      <TableCell className={classes.messageTitleCell} onClick={event => setAnchorEl(event.currentTarget)}>
        <div className={classes.messageTitle}>
          {messageTypeIcon(messengerType, channelStat.channelState)}
          <span>{channelStat.channelType}</span>
        </div>
      </TableCell>
      <TableCell>{channelStat.incomingCount}</TableCell>
      <TableCell>{channelStat.pendingCount}</TableCell>
      <TableCell>{channelStat.outgoingCount}</TableCell>
      <TableCell>{channelStat.outgoingSuccessCount} ({channelStat.outgoingSuccessPercent}%)</TableCell>
      <TableCell>{renderDate(channelStat?.lastOutgoingAt)}</TableCell>
      <TableCell>{renderDate(channelStat?.lastSuccessOutgoingAt)}</TableCell>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={() => setAnchorEl(null)}
               anchorOrigin={{
                 vertical: 'top',
                 horizontal: 'center'
               }}
               transformOrigin={{
                 vertical: 'bottom',
                 horizontal: 'center'
               }}>
        <ChannelAddition channel={channelStat} afterCopy={afterCopy}/>
      </Popover>
    </TableRow>
  )
}

function ChannelTable({ messengerStat }: TableProps) {
  const dispatch = useDispatch();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEAD.map(t => (
              <TableCell>
                {t?.icon}
                {t?.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {messengerStat.stats.map(cs => (
            <ChannelStatRow key={cs.channelId}
                            channelStat={cs}
                            afterCopy={() => dispatch(setSnackbar({ open: true, text: 'ID канала скопирован' }))}
                            messengerType={messengerStat.messengerType}/>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default ChannelTable;
