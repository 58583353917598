import { CurrentUser } from '../../store/current-user/types';
import * as datefns from 'date-fns';

const userKey = 'user:current';

function storeUser(data: CurrentUser): void {
	localStorage.setItem(userKey, JSON.stringify(data));
}

function getUser(): undefined | CurrentUser {
	const data = localStorage.getItem(userKey);

	if (!data) {
		return;
	}

	const user = JSON.parse(data);

	if (isAccessTokenExpired(user.accessToken)) {
		clear();

		return;
	}

	return user;
}

function isAccessTokenExpired(accessToken: string): boolean {
	const [, payload] = accessToken.split('.');

	if (!payload) {
		return true;
	}

	const { exp } = JSON.parse(atob(payload)) as { exp: any };

	if (!exp || typeof exp !== 'number') {
		return true;
	}

	return datefns.isPast(datefns.fromUnixTime(exp));
}

function clear(): void {
	localStorage.removeItem(userKey);
}

export const userCacheStorage = { storeUser, getUser, clear };
