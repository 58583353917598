import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, LinearProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import ContactsChipList from './ContactsChipList';
import ChipsFaqPopover from './ChipsFaqPopover';
import { Autocomplete, AutocompleteRenderInputParams, Skeleton } from '@material-ui/lab';
import TextDivider from '../common/TextDivider';
import { useToggle } from 'react-use';
import { broadcastsApi } from '../../utils/api/broadcasts';
import {
  BroadcastCompletedStepsOutput,
  BroadcastContactsFilter,
  BroadcastDetailsOutput,
  BroadcastingContactOutput
} from '../../utils/api/broadcasts.types';

const useStyles = makeStyles({
  wrapper: {
    display: 'grid',
    gridGap: '20px',
    padding: '20px',
    overflow: 'auto',
    gridTemplateRows: 'min-content 1fr',
    position: 'relative'
  },
  chipsList: {
    display: 'flex',
    flexWrap: 'wrap',
    gridGap: '15px',
    alignContent: 'start',
    padding: '10px 0'
  },
  progressWrapper: {
    display: 'flex',
    gridGap: '10px',
    alignItems: 'center'
  },
  progress: {
    height: '10px',
    borderRadius: '5px',
    width: '100%'
  },
  topPart: {
    display: 'grid',
    gridGap: '20px'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

function BroadcastDetails() {
  const classes = useStyles();
  const params: { broadcastId: string } = useParams();
  const limit = 25;

  const [total, setTotal] = useState(0);
  const [contacts, setContacts] = useState(Array<BroadcastingContactOutput>());
  const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState({} as BroadcastCompletedStepsOutput);
  const [contactFilter, setContactFilter] = useState('');
  const [details, setDetails] = useState({ allContacts: Array<string>() } as BroadcastDetailsOutput)
  const [detailsLoading, toggleDetailsLoading] = useToggle(false);
  const [contactsLoading, toggleContactsLoading] = useToggle(false);

  const getDetails = () => {
    toggleDetailsLoading();

    broadcastsApi.getBroadcastDetails(params.broadcastId)
      .then(c => setDetails(c))
      .then(() => toggleDetailsLoading());
  }

  const getContacts = (filter: BroadcastContactsFilter) => {
    broadcastsApi.getBroadcastContacts(params.broadcastId, filter)
      .then(c => {
        setContacts(c.data);
        setTotal(c.total);
      })
  };

  const getSteps = () => {
    broadcastsApi.getBroadcastSteps(params.broadcastId)
      .then(steps => {
        setSteps(steps);
        setProgress(100 / steps.totalSteps * steps.completedSteps);
      });
  }

  const getMoreContacts = () => {
    toggleContactsLoading();

    broadcastsApi.getBroadcastContacts(params.broadcastId, {
      limit,
      offset: contacts.length,
      contact: contactFilter || undefined
    })
      .then(c => setContacts(contacts.concat(c.data)))
      .then(() => toggleContactsLoading());
  }

  const getFilteredContacts = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && getContacts({ contact: contactFilter, limit }),
    [contactFilter, getContacts]
  );

  useEffect(() => {
    getContacts({ limit });
    getSteps();
    getDetails();
  }, [params.broadcastId])

  useEffect(() => {
    const timer = setInterval(getSteps, 3000)

    return () => clearInterval(timer);
  }, [progress, params]);

  const autocompleteRender = (params: AutocompleteRenderInputParams) => (
    <TextField {...params}
               onChange={e => setContactFilter(e.target.value as string)}
               value={contactFilter}
               label="Поиск по номеру"
               variant="outlined"
               onKeyPress={getFilteredContacts}
    />
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.topPart}>
        <div className={classes.head}>
          {!detailsLoading
            ? <Typography variant="h5">{details.title} - {steps.completedSteps} из {steps.totalSteps}</Typography>
            : <Skeleton variant="text" width={300} height={50}/>
          }


          <div style={{ display: 'flex', alignItems: 'center', gridGap: '10px' }}>
            <span>{details.countries?.map(c => c.isoCode).join(', ')}</span>

            <ChipsFaqPopover/>
          </div>
        </div>

        <div className={classes.progressWrapper}>
          <LinearProgress variant="determinate" value={progress} className={classes.progress}/>

          <Typography variant="body1">{progress}%</Typography>
        </div>

        <TextDivider variant="left">Текст сообщения</TextDivider>

        {!detailsLoading
          ? <Typography variant="body1">{details.text}</Typography>
          : <div>
            <Skeleton variant="text" animation="wave" height={30} width="100%"/>
            <Skeleton variant="text" width="70%" height={30}/>
          </div>
        }

        <Divider/>

        <Autocomplete onChange={(event: any, newValue: string | null) => setContactFilter(newValue || '')}
                      onInputChange={(event: any, newValue: string | null) => setContactFilter(newValue || '')}
                      renderInput={autocompleteRender}
                      options={details.allContacts}/>
      </div>

      <div style={{ overflow: 'auto' }}>
        <div className={classes.chipsList}>
          <ContactsChipList contacts={contacts}/>
        </div>

        {contactsLoading &&
        <div style={{ padding: '10px 0' }}>
          <LinearProgress style={{ display: 'grid' }} color="primary"/>
        </div>
        }

        {contacts.length < total &&
        <div style={{ display: 'grid', justifyContent: 'center' }}>
          <Button onClick={getMoreContacts} color="primary">Показать больше...</Button>
        </div>
        }
      </div>
    </div>
  )
}

export default BroadcastDetails;
