import { Divider, makeStyles, Paper } from '@material-ui/core';
import React from 'react';

type Props = { leftSide: React.ReactNode, rightSide: React.ReactNode }

const useStyles = makeStyles({
  wrapper: {
    display: 'grid',
    justifyContent: 'center'
  },
  mainGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content 2fr',
    borderRadius: '10px',
    margin: '20px',
    height: '85vh',
    width: '80vw',
    borderTopLeftRadius: 0
  }
});

function Layout({ leftSide, rightSide }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Paper variant="outlined" className={classes.mainGrid}>
        {leftSide}
        <Divider orientation="vertical"/>
        {rightSide}
      </Paper>
    </div>
  );
}

export default Layout;
