import Layout from '../Layout';
import ChannelsList from './ChannelsList';
import ChannelDetails from './details/ChannelDetails';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AbsoluteAddButton from '../chats/new-chat/AbsoluteAddButton';
import { Dialog } from '@material-ui/core';
import { useState } from 'react';
import CreateChannelDialog from './details/CreateChannelDialog';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/clipboard/actions';

function Channels() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const left = (<ChannelsList/>)
  const right = (
    <Switch>
      <Route path={`${path}/:channelId`}>
        <ChannelDetails/>
      </Route>
    </Switch>
  )

  const afterCreate = () => {
    setOpen(false);

    dispatch(setSnackbar({ open: true, text: 'Канал создан' }));
  }

  return (
    <>
      <Layout leftSide={left} rightSide={right}/>

      <AbsoluteAddButton onClick={() => setOpen(true)}/>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <div style={{ padding: '1rem' }}>
          <CreateChannelDialog afterCreate={afterCreate}/>
        </div>
      </Dialog>
    </>
  );
}

export default Channels;
