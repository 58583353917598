import { SnackbarActions, SnackbarState } from './types';

const initialState: SnackbarState = {
  data: { open: false }
};

export function snackbarReducers(state = initialState, action: SnackbarActions): SnackbarState {
  switch (action.type) {
    case 'SET_SNACKBAR_OPEN':
      return {
        data: { ...action.data }
      }

    default:
      return state;
  }
}
