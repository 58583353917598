import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

type Props = { disabled?: boolean, action: (text: string) => void };

function MessageTextInput({ action, disabled }: Props) {

  const [text, setText] = useState('');

  useEffect(() => {
    const onKeypress = (e: KeyboardEvent) => (e.ctrlKey && e.code === 'Enter') && send(text);

    document.addEventListener('keypress', onKeypress);

    return () => {
      document.removeEventListener('keypress', onKeypress);
    };
  }, [text]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const send = (text: string) => {
    action(text);
    setText('');
  }

  return (
    <FormControl>
      <OutlinedInput placeholder="Напишите сообщение..."
                     value={text}
                     multiline
                     onChange={handleChange}
                     rowsMax={3}
                     disabled={disabled}
                     endAdornment={
                       <InputAdornment position="end">
                         <IconButton onClick={() => send(text)}>
                           <ArrowForward color={text.length ? 'primary' : 'action'}/>
                         </IconButton>
                       </InputAdornment>
                     }
      />
    </FormControl>
  )
}

export default MessageTextInput;
