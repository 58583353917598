import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import MessageTextInput from './MessageTextInput';
import MessageView from './MessageView';
import { formatDate, parseDate } from '../../../utils/functions';
import { useToggle } from 'react-use';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { messagesApi } from '../../../utils/api/messages';
import { MessageOutput } from '../../../utils/api/messages.types';

type MessagesGroup = { date: string, messages: MessageOutput[] };

type Props = { disableInput: boolean };

const useStyles = makeStyles({
  main: {
    display: 'grid',
    gridGap: '1rem',
    padding: '20px',
    overflow: 'auto'
  },
  messages: {
    overflow: 'auto',
    padding: '5px'
  },
  miniSpace: {
    display: 'grid',
    gridGap: '0.5rem',
    overflow: 'hidden'
  },
  space: {
    display: 'grid',
    gridGap: '1rem'
  }
});

function Dialog({ disableInput }: Props) {
  const classes = useStyles();
  const params: { dialogId: string } = useParams();
  const limit = 25;

  const [messageGroups, setMessageGroups] = useState(Array<MessagesGroup>())
  const [messages, setMessages] = useState(Array<MessageOutput>());
  const [totalCount, setTotalCount] = useState(0);
  const [loading, toggleLoading] = useToggle(false);

  const splitByDate = (messagesToSplit: MessageOutput[]) => {
    const format = 'dd.MM.yyyy';

    const allDates = messagesToSplit.map(m => formatDate(m.sentAt, format));
    const datesSet = new Set<string>(allDates);
    const uniqDates = Array.from(datesSet);

    // Делим на группы по дате
    const groups: MessagesGroup[] = uniqDates
      .map(date => {
        const messagesGroup = messagesToSplit.filter(m => formatDate(m.sentAt, format) === date);

        return { date, messages: messagesGroup };
      });

    return groups;
  }

  const sendMessage = (text: string) => {
    if (!text?.length || !params) {
      return;
    }

    messagesApi.sendMessageToConversation(params.dialogId, text);

    // const newMsg = {
    //   type: 'Outgoing',
    //   state: 'Pending',
    //   content: text || '',
    //   sentAt: parseDate(new Date().toString()).toString()
    // } as MessageOutput;
    //
    // setMessages([newMsg, ...messages]);
  }

  const getMoreMessages = useCallback(() => {
    if (!params) {
      return;
    }

    messagesApi.getMessages(params.dialogId, { limit, offset: messages.length })
      .then(m => setMessages(messages.concat(m.data)))
  }, [messages, params]);

  useEffect(() => {
    if (!params) {
      return;
    }

    toggleLoading();

    messagesApi.getMessages(params.dialogId, { limit })
      .then(m => {
        setMessages(m.data);
        setTotalCount(m.total);
      })
      .then(toggleLoading)
  }, [params.dialogId])

  useEffect(() => {
    const timer = setInterval(() => {
      messagesApi.getMessages(params.dialogId, { limit })
        .then(response => {
          // Берем первые 25 сообщений и находим разницу с вновь запрошенными
          const firstPack = messages.slice(0, 25);
          const diff = response.data.filter(m => !firstPack.some(fp => m.id === fp.id));

          if (!diff.length) {
            return;
          }

          // Разницу записываем в начало
          setMessages([...diff, ...messages]);
        })
    }, 5000);

    return () => clearInterval(timer);
  }, [params, messages])

  useEffect(() => {
    const groups = splitByDate(messages);
    setMessageGroups(groups);
  }, [messages]);

  const scroll = (
    <div id="scrollableDiv"
         style={{
           height: '100%',
           overflow: 'auto',
           display: 'flex',
           flexDirection: 'column-reverse'
         }}
    >
      {/*Put the scroll bar always on the bottom*/}
      <InfiniteScroll
        dataLength={messages.length}
        next={getMoreMessages}
        style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        inverse={true}
        hasMore={messages.length < totalCount}
        loader={<LinearProgress style={{ height: '7px' }}/>}
        scrollableTarget="scrollableDiv"
      >
        <div className={`${classes.messages} ${classes.miniSpace}`}>
          {messageGroups?.map((g, i) => (
            <div style={{ order: 0 - i }} className={classes.space}>
              <Typography variant="subtitle2" align="center">{g.date}</Typography>
              <div className={classes.miniSpace}>
                {g.messages.map((m, i) => (<MessageView key={m.id} message={m} order={0 - i}/>))}
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );

  return (
    <div className={classes.main} style={{ alignContent: !messages.length && !loading ? 'center' : 'end' }}>
      {!messages.length && !loading &&
      <Typography style={{ textAlign: 'center' }} variant="h5">Выберите чат</Typography>}

      {!loading
        ? scroll
        : <div className={`${classes.messages} ${classes.miniSpace}`}>
          {[1, 2, 3, 4, 5, 6].map(i => (
            <Skeleton width="45%" height="75px" variant="rect" animation="wave"
                      style={{ justifySelf: i % 2 === 0 ? 'start' : 'end' }}
            />
          ))}
        </div>
      }

      {(!!messages.length || loading) &&
      <MessageTextInput disabled={disableInput} action={sendMessage}/>}
    </div>
  )
}

export default Dialog;
