import { PaginatedData } from './common.types';
import axios from 'axios';
import { Country, CurrentUser, Project } from '../../store/current-user/types';

type AuthInput = {
	login: string,
	password: string
}

function login(input: AuthInput): Promise<CurrentUser> {
	return axios.post(`/auth/login`, input).then(it => it.data);
}

function getProjects(): Promise<PaginatedData<Project>> {
	return axios.get('/projects').then(it => it.data);
}

function getCountries(): Promise<PaginatedData<Country>> {
	return axios.get('/countries').then(it => it.data);
}

export const userApi = {
	login,
	getProjects,
	getCountries
}
