import { PaginatedData } from './common.types';
import axios from 'axios';
import { paramsToString } from '../functions';
import {
  BroadcastCompletedStepsOutput,
  BroadcastContactsFilter,
  BroadcastDetailsOutput,
  BroadcastingContactOutput,
  BroadcastInput,
  BroadcastOutput,
  BroadcastsFilterInput
} from './broadcasts.types';

function getBroadcasts(pagination: BroadcastsFilterInput): Promise<PaginatedData<BroadcastOutput>> {
  return axios
    .get(`/broadcasts?${paramsToString(pagination)}`)
    .then(it => it.data)
}

function createBroadcast(input: BroadcastInput): Promise<BroadcastOutput> {
  return axios
    .post('/broadcasts', input)
    .then(it => it.data)
}

function getBroadcastContacts(broadcastId: string, filter: BroadcastContactsFilter): Promise<PaginatedData<BroadcastingContactOutput>> {
  return axios
    .get(`/broadcasts/${broadcastId}/contacts?${paramsToString(filter)}`)
    .then(it => it.data)
}

function getBroadcastSteps(broadcastId: string): Promise<BroadcastCompletedStepsOutput> {
  return axios
    .get(`/broadcasts/${broadcastId}/steps`)
    .then(it => it.data)
}

function getBroadcastDetails(broadcastId: string): Promise<BroadcastDetailsOutput> {
  return axios
    .get(`/broadcasts/${broadcastId}/details`)
    .then(it => it.data);
}

export const broadcastsApi = {
  getBroadcasts,
  createBroadcast,
  getBroadcastContacts,
  getBroadcastSteps,
  getBroadcastDetails
}
