import { ChannelAdditions, Statistic } from '../../../utils/api/statistic.types';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import { FileCopyOutlined, Link } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { absurd } from "../../../utils/functions";

type Props = { channel: Statistic.ChannelStatisticDto, afterCopy: () => void };

function ChannelAddition({ channel, afterCopy }: Props) {
  const history = useHistory();

  const copyId = () => {
    navigator.clipboard.writeText(channel.channelId).then(afterCopy)
  }

  const toNavigateChannel = () => {
    history.push(`/channels/${channel.channelId}`)
  }

  const getAdditionContent = (addition: ChannelAdditions.All) => {
    switch (addition.type) {
      case 'SemySms':
        return (
          <ListItemText primary={addition.deviceId} secondary="ID устройства"/>
        );

      case 'SmppSms':
        return (
          <div>
            <ListItemText primary={addition.host} secondary="Хост"/>
            <ListItemText primary={addition.line} secondary="Порт"/>
            {addition.phoneNumber && <ListItemText primary={addition.phoneNumber} secondary="Номер телефона"/>}
          </div>
        );

      case 'Twilio':
      case 'GoipHttpApi':
        return (
          <ListItemText primary={addition.phoneNumber} secondary="Номер телефона"/>
        )

      case 'Yeastar':
        return (
          <div>
            <ListItemText primary={addition.host} secondary="Хост"/>
            <ListItemText primary={addition.line} secondary="Порт"/>
            <ListItemText primary={addition.account} secondary="Аккаунт"/>
          </div>
        );

      case 'HtmlWeb':
        return (
          <ListItemText primary={addition.gateId} secondary="Id шлюза"/>
        );

      case 'SmgHttp':
        return (
          <div>
            <ListItemText primary={addition.url} secondary="Url хоста"/>
            <ListItemText primary={addition.port} secondary="Порт"/>
          </div>
        );

      case 'SmsCountry':
        return (
          <div>
            <ListItemText primary={addition.username} secondary="Username"/>
          </div>
        )

      case 'GupShup':
        return (
          <div>
            <ListItemText primary={addition.app} secondary="App name"/>
            <ListItemText primary={addition.sourcePhone} secondary="Номер телефона"/>
          </div>
        )
    }

    absurd(addition);
  }

  return (
    <List dense style={{ minWidth: '300px' }}>
      <ListItem>
        {getAdditionContent(channel.channelAdditions)}

        <ListItemSecondaryAction>
          <Tooltip title="Копировать ID канала">
            <IconButton onClick={copyId} color="primary">
              <FileCopyOutlined/>
            </IconButton>
          </Tooltip>

          <Tooltip title="Перейти на страницу канала">
            <IconButton onClick={toNavigateChannel} color="primary">
              <Link/>
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

export default ChannelAddition;
