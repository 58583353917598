import { Button, Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../store/root-state';
import { Project } from '../../store/current-user/types';
import { setSelectedProject } from '../../store/current-user/actions';
import { projectCacheStorage } from '../../utils/cache/project-cache-storage';

function ProjectSelector() {
  const projects = useSelector(selectors.projects);
  const dispatch = useDispatch();
  const oneProject = projects?.length === 1;
  const cachedProject = projectCacheStorage.getProject();
  const storedProject = useSelector(selectors.selectedProject);

  // Если проект один, то он
  // Если проектов много, но есть кешированный, то он
  // Иначе пустой объект
  const [project, setProject] = useState(oneProject
    ? projects[0]
    : cachedProject
      ? cachedProject
      : {} as Project
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setProject(projects[index]);
    setAnchorEl(null);

    projectCacheStorage.storeProject(projects[index]);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (oneProject) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    dispatch(setSelectedProject({ ...project }));
  }, [project, dispatch])

  return (
    <>
      <Button style={{ color: 'white' }} onClick={handleMenuClick} disabled={oneProject}>
        {storedProject?.name || 'Проект'}
        {!oneProject && <ExpandMore/>}
      </Button>

      {!oneProject && <Menu anchorEl={anchorEl}
                             open={Boolean(anchorEl)}
                             onClose={() => setAnchorEl(null)}>
        {projects.map((p, i) => (
          <MenuItem key={p.id}
                    selected={selectedIndex === i}
                    onClick={event => handleMenuItemClick(event, i)}>
            {p.name}
          </MenuItem>
        ))}
      </Menu>}
    </>
  );
}

export default ProjectSelector;
