import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';

type Data = { id: string, name: string }

type Props = {
	labelId: string,
	values: Data[],
	value: string[],
	onChange: (values: string[]) => void
}

function WrappedSelect(props: Props) {
	const multiple = props.values.length > 1;

	const renderValue = (selected: unknown) => {
		const normalized = (Array.isArray(selected) ? selected : [selected]) as string[];

		return normalized.map(s => props.values.find(v => v.id === s)?.name).join(', ');
	};

	return (
		<Select
			labelId={props.labelId}
			value={props.value}
			multiple={multiple}
			onChange={e => props.onChange(e.target.value as string[])}
			renderValue={renderValue}
		>
			{props.values.map(v => (
				<MenuItem
					key={v.id}
					value={v.id}
				>
					{multiple &&
          <Checkbox checked={props.value.includes(v.id)} color='primary' />
					}
					<ListItemText primary={v.name} />
				</MenuItem>
			))}
		</Select>
	);
}

export default WrappedSelect;
