import { MessageStatisticActions, MessageStatisticState } from "./types";
import * as datefns from "date-fns";

const initialState: MessageStatisticState = {
	isLoading: false,
	filters: {
		countryIds: [],
		projectIds: [],
		dates: [datefns.startOfDay(new Date()), new Date()]
	},
	stats: undefined
}

export function messageStatisticReducer(
	state = initialState,
	action: MessageStatisticActions
): MessageStatisticState {
	switch (action.type) {
		case 'SET_MESSAGE_STATISTIC':
			return {
				...state,
				stats: action.stats
			};

		case 'SET_MESSAGE_STATISTIC_FILTERS':
			return {
				...state,
				filters: action.filters
			};

		case 'SET_MESSAGE_STATISTIC_IS_LOADING':
			return {
				...state,
				isLoading: action.value
			}

		default:
			return state;
	}
}
