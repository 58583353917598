import { Badge, Chip, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { AccessTime, Block, CheckCircleOutline } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { MessageOutput } from '../../utils/api/messages.types';
import { BroadcastingContactOutput } from '../../utils/api/broadcasts.types';

type Props = { contacts: BroadcastingContactOutput[] };
type ChipData = { icon: JSX.Element, color: 'primary' | 'secondary' | 'default' }

function ContactsChipList({ contacts }: Props) {
  const history = useHistory();

  const getChipData = (c: BroadcastingContactOutput): ChipData => {
    switch (c.type) {
      case 'NotProcessedYet':
        return { icon: <AccessTime color="action"/>, color: 'default' };

      case 'NotAvailableToProcess':
        return { icon: <Block color="error"/>, color: 'secondary' };

      case 'ProcessedWithNewConversation':
      case 'ProcessedWithExistedConversation':
        const icon = <CheckCircleOutline/>;
        const color: ChipData['color'] = getMessageStateColor(c.message?.state);

        return { icon, color };
    }
  }

  const getMessageStateColor = (state: MessageOutput['state'] | undefined) => {
    switch (state) {
      case 'Error':
        return 'secondary';

      case 'Sent':
      case 'Read':
        return 'primary';

      case 'Pending':
      default:
        return 'default'
    }
  }

  const getCursorStyle = useCallback((m: MessageOutput | null) => m ? 'pointer' : 'not-allowed', []);

  return (
    <>
      {!contacts.length && <Typography variant="body1">Нет данных</Typography>}

      {contacts.map(c => {
        const data = getChipData(c);

        return (
          <Badge key={c.id}
                 color="secondary"
                 badgeContent={'New'}
                 invisible={c.type !== 'ProcessedWithNewConversation'}
                 anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
            <Chip label={c.contact}
                  clickable={!!c.message?.id}
                  onClick={() => c.message?.conversation && history.push(`/chats/${c.message.conversation.id}`)}
                  icon={data.icon}
                  color={data.color}
                  variant="outlined"
                  style={{ fontSize: '16px', cursor: getCursorStyle(c.message) }}
            />
          </Badge>
        )
      })}
    </>
  );
}

export default ContactsChipList;
