import {
  ChannelFilters, ChannelInput,
  ChannelOutput, ChannelState,
  GoipHttpApiChannelOutput,
  GoipHttpChannelInput, HtmlWebChannelInput, HtmlWebChannelOutput,
  SemySmsChannelInput,
  SemySmsChannelOutput, SmgHttpChannelInput, SmgHttpChannelOutput,
  SmppSmsChannelInput,
  SmppSmsChannelOutput,
  TwilioChannelInput,
  TwilioChannelOutput,
  YeastarChannelInput,
  YeastarChannelOutput
} from './channels.types';
import axios from 'axios';
import { paramsToString } from '../functions';
import { PaginatedData } from './common.types';

function getChannels(filter: ChannelFilters): Promise<PaginatedData<ChannelOutput>> {
  return axios.get(`/channels?${paramsToString(filter)}`).then(it => it.data)
}

function getChannel(id: string): Promise<ChannelOutput> {
  return axios.get(`/channels/${id}`).then(it => it.data);
}

function createChannel(input: ChannelInput): Promise<ChannelOutput> {
  return axios.post('/channels', input).then(it => it.data);
}

function updateChannel(channelId: string, input: ChannelInput): Promise<void> {
  return axios.put(`/channels/${channelId}`, input);
}

function updateChannelState(channelId: string, state: ChannelState): Promise<void> {
  return axios.patch(`/channels/${channelId}/state`, { state });
}

function createSemySmsChannel(input: SemySmsChannelInput): Promise<SemySmsChannelOutput> {
  return axios.post('/channels/semy-sms', input).then(it => it.data)
}

function createSmppSmsChannel(input: SmppSmsChannelInput): Promise<SmppSmsChannelOutput> {
  return axios.post('/channels/smpp-sms', input).then(it => it.data)
}

function createGoipHttpChannel(input: GoipHttpChannelInput): Promise<GoipHttpApiChannelOutput> {
  return axios.post('/channels/goip-http-api', input).then(it => it.data)
}

function createTwilioChannel(input: TwilioChannelInput): Promise<TwilioChannelOutput> {
  return axios.post('/channels/twilio', input).then(it => it.data)
}

function createYeastarChannel(input: YeastarChannelInput): Promise<YeastarChannelOutput> {
  return axios.post('/channels/semy-sms', input).then(it => it.data)
}

function createHtmlWebChannel(input: HtmlWebChannelInput): Promise<HtmlWebChannelOutput> {
  return axios.post('/channels/html-web', input).then(it => it.data);
}

function createSmgHttpChannel(input: SmgHttpChannelInput): Promise<SmgHttpChannelOutput> {
  return axios.post('/channels/smg-http', input).then(it => it.data);
}

export const channelsApi = {
  getChannels,
  getChannel,
  createChannel,
  updateChannel,
  updateChannelState,
  createSemySmsChannel,
  createSmppSmsChannel,
  createGoipHttpChannel,
  createTwilioChannel,
  createYeastarChannel,
  createHtmlWebChannel,
  createSmgHttpChannel,
}
