import { useSelector } from 'react-redux';
import { selectors } from '../../../store/root-state';
import { LinearProgress, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import CountryStatTable from './CountryStatisticTable';

type Props = { refresh: () => void }

const useStyles = makeStyles({
	wrapper: {
		padding: ' 25px 50px'
	}
});

function TabContent(props: { children?: React.ReactNode, isSelected: boolean }) {
	return (
		<div hidden={!props.isSelected}>
			{props.children}
		</div>
	)
}

function Statistic({ refresh }: Props) {
	const classes = useStyles();

	const isLoading = useSelector(selectors.messageStatistic.isLoading);
	const stats = useSelector(selectors.messageStatistic.stats);

	const [tabValue, setTabValue] = useState(0);

	const tabIsOutOfRange = useCallback(() => {
		return undefined === stats || tabValue > (stats.stats.length - 1)
	}, [stats, tabValue]);

	useEffect(() => {
		if (tabIsOutOfRange()) {
			setTabValue(0);
		}
	}, [tabIsOutOfRange])

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue);
	};

	return (
		<div className={classes.wrapper}>
			{isLoading && <LinearProgress variant="indeterminate" />}

			{stats?.stats.length
				? (<React.Fragment>
					<Tabs style={{ marginBottom: '10px' }}
						  value={tabIsOutOfRange() ? 0 : tabValue}
						  onChange={handleChange}
						  textColor="primary"
						  indicatorColor="primary"
						  centered>
						{stats?.stats.map((ps, i) => (<Tab key={i} label={ps.projectName}/>))}
					</Tabs>

					<div>
						{stats?.stats.map((ps, i) => (
							<TabContent key={i} isSelected={tabValue === i}>
								<CountryStatTable countryStatistic={ps.stats} refresh={refresh}/>
							</TabContent>
						))}
					</div>
				</React.Fragment>)
				: <h1 style={{ textAlign: 'center' }}> No data </h1>
			}
		</div>
	);
}

export default Statistic;
