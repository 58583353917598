import { Route } from 'react-router-dom';
import React from 'react';
import Header from './header/Header';
import MessageStatistic from './message-statistic/MessageStatistic';
import Chats from './chats/Chats';
import Broadcast from './broadcast/Broadcast';
import Channels from './channels/Channels';

function AppRoutes() {
  return (
    <>
      <Header/>

      <Route exact path="/message-statistic">
        <MessageStatistic/>
      </Route>

      <Route path="/channels">
        <Channels/>
      </Route>

      <Route path="/broadcast">
        <Broadcast/>
      </Route>

      <Route path="/chats">
        <Chats/>
      </Route>
    </>
  );
}

export default AppRoutes;
