import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { messageTypeIcon, renderDate } from '../../../utils/functions';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ConversationOutput } from '../../../utils/api/messages.types';

type Props = { conversations: ConversationOutput[], onConversationChanged: (conversation: ConversationOutput) => void }

const useStyles = makeStyles({
  list: {
    backgroundColor: 'aliceblue',
    padding: 0,
    borderRadius: '10px',
    borderTopLeftRadius: 0
  },
  listItemText: {
    '& span': {
      fontSize: '1.2rem'
    }
  },
  noData: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
});

function ConversationsList({ conversations, onConversationChanged }: Props) {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();

  const getDialogId = () => {
    const parts = history.location.pathname.split('/');
    return parts[parts.length - 1];
  }

  const [selectedId, setSelectedId] = useState(getDialogId());

  const selectDialog = (c: ConversationOutput) => {
    setSelectedId(c.id);
    onConversationChanged(c)

    history.push(`${url}/${c.id}`);
  }

  return (
    <>
      {conversations.length
        ? <List className={classes.list}>
          {conversations?.map(c => (
            <div key={c.id}>
              <ListItem selected={c.id === selectedId}
                        button
                        onClick={() => selectDialog(c)}>
                <ListItemIcon>
                  {messageTypeIcon(c.channel.messengerType, c.channel.state)}
                </ListItemIcon>

                <ListItemText primary={`${c.contact} - ${c.channel.type}`}
                              secondary={renderDate(c.createdAt)}
                              className={classes.listItemText}
                />
              </ListItem>
              <Divider/>
            </div>
          ))}
        </List>
            : <div className={classes.noData}>
              <Typography variant="h4">Нет данных</Typography>
            </div>
        }
      </>
  );
}

export default ConversationsList;
