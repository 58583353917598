import Filters from './Filters';
import { useDispatch, useSelector } from 'react-redux';
import {
	completeMessageStatisticLoading,
	setMessageStatistic,
	startMessageStatisticLoading
} from '../../store/message-statistic/actions';
import { selectors } from '../../store/root-state';
import Statistic from './Tables/Statistic';
import { Button, Drawer, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { statisticApi } from '../../utils/api/statistic';

const useStyles = makeStyles({
	wrapper: {
		width: '100%',
		height: '100%',

		'& > div': {
			overflowY: 'auto'
		}
	},

	buttonWrapper: {
		display: 'grid',
		justifyContent: 'center',
		gridAutoColumns: '200px',
		padding: '10px'
	}
});

function MessageStatistic() {
	const dispatch = useDispatch();
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [refresh, toggleRefresh] = useToggle(false);

	const filters = useSelector(selectors.messageStatistic.filters);

	useEffect(() => {
		setOpen(false);

		const { dates, projectIds, countryIds } = filters;

		const from = dates[0]?.toISOString();
		const to = dates[1]?.toISOString();

		dispatch(startMessageStatisticLoading());

		statisticApi.getMessagesStatistic({ projectIds, countryIds, from, to })
			.then((statistics) => {
				dispatch(setMessageStatistic(statistics))
			})
			.finally(() => {
				dispatch(completeMessageStatisticLoading())
			})
	}, [filters, refresh, dispatch]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.buttonWrapper}>
				<Button variant="outlined" color="primary" onClick={() => setOpen(!open)}>Фильтр</Button>
			</div>

			<Drawer open={open} anchor="top" onClose={() => setOpen(false)}>
				<Filters/>
			</Drawer>

			<Statistic refresh={() => toggleRefresh()}/>
		</div>
	);
}

export default MessageStatistic;
