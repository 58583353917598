import { ChannelOutput } from '../../../utils/api/channels.types';
import { Divider, List, ListItemText, makeStyles, Popover, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { channelsApi } from '../../../utils/api/channels';
import { Skeleton } from '@material-ui/lab';
import { useToggle } from 'react-use';
import { formatDate } from '../../../utils/functions';
import EditableDetails from './editable/EditableDetails';
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles({
  grid: {
    padding: '1rem',
    display: 'grid',
    gridGap: '2rem',
    alignContent: 'start',
    overflow: 'auto'
  },
  common: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    gridGap: '20px',
    alignContent: 'start'
  },
  individual: {
    display: 'grid',
    alignContent: 'start',
    gridTemplateColumns: 'minmax(240px, 500px)',
    gridGap: '10px',
    justifyContent: 'center',
    overflow: 'auto'
  },
  head: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gridGap: '20px',
    alignItems: 'center'
  }
})

function ChannelDetails() {
  const classes = useStyles();
  const params: { channelId: string } = useParams();

  const [channel, setChannel] = useState<ChannelOutput>();
  const [loading, toggleLoading] = useToggle(false);

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const isOpen = Boolean(anchorEl);


  useEffect(() => {
    toggleLoading();

    channelsApi.getChannel(params.channelId)
      .then(c => setChannel(c))
      .then(() => toggleLoading());
  }, [params.channelId])

  return (
    <div className={classes.grid}>
      <div className={classes.head}>
        <Typography variant="h5">
          {!loading
            ? <>
              {channel?.type} - {channel?.state}
              <ExpandMore fontSize='small' color='primary' onClick={(e) => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer'}}/>
              <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}>
                <div style={{ padding: '10px' }}>Изменение состояния канала (coming soon...)</div>
              </Popover>
            </>
            : <Skeleton animation="wave" variant="rect" width={200} height={32}/>
          }
        </Typography>

        {channel?.bannedAt && !loading
        && <ListItemText style={{ textAlign: 'center' }} primary={formatDate(channel.bannedAt)} secondary="Забанен"/>
        }

        {!loading
          ? <ListItemText style={{ textAlign: 'end' }} primary={formatDate(channel?.createdAt ?? null)}
                          secondary="Создан"/>
          : <Skeleton animation="wave" variant="rect" width={200} height={32}/>
        }
      </div>

      {!loading
        ? (<EditableDetails channel={channel}/>)
        : (
          <>
            <Divider/>
            <List className={classes.individual}>
              {[1, 2, 3, 4, 5].map(i => (<Skeleton key={i} animation="wave" variant="rect" height={60}/>))}
            </List>
          </>
        )
      }
    </div>
  )
}

export default ChannelDetails;
