import { BaseDetailProps } from "../editable/EditableFields";
import { HtmlWebChannelOutput } from "../../../../utils/api/channels.types";
import { useEffect, useState } from "react";
import { channelsApi } from "../../../../utils/api/channels";
import { Devices, VpnKeyOutlined } from "@material-ui/icons";
import IndividualEditableFields from "../editable/IndividualEditableFields";

type Props = BaseDetailProps & {
	channel?: HtmlWebChannelOutput
};

function HtmlWebDetails({ channel, emitter, acceptFn, editMode }: Props) {
	const [apiKey, setApiKey] = useState(channel?.apiKey ?? '');
	const [gateId, setGateId] = useState(channel?.gateId ?? '');

	const [details, setDetails] = useState([
		{ primary: apiKey, secondary: 'Api ключ', icon: <VpnKeyOutlined/>, copyable: true, setValue: setApiKey },
		{ primary: gateId, secondary: 'ID шлюза', icon: <Devices/>, copyable: true, setValue: setGateId }
	]);

	useEffect(() => {
		const accept = () => {
			const request = (input: any) => !channel
				? channelsApi.createHtmlWebChannel(input)
				: new Promise(() => console.log('create', input));

			acceptFn({ apiKey, gateId }, request);
		};

		emitter.addListener('accept', accept);

		return () => {
			emitter.removeListener('accept', accept);
		}
	});

	useEffect(() => {
		const reset = () => {
			setApiKey(channel?.apiKey ?? '');
			setGateId(channel?.gateId ?? '');
		};

		emitter.addListener('reset', reset);

		return () => {
			emitter.removeListener('reset', reset);
		}
	});

	useEffect(() => {
		setDetails([
			{ ...details[0], primary: apiKey },
			{ ...details[1], primary: gateId }
		])
	}, [apiKey, gateId]);

	return (
		<IndividualEditableFields editMode={editMode} details={details}/>
	);
}

export default HtmlWebDetails;
