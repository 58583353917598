import { CurrentUserActions, CurrentUser, Project, Country } from "./types";

export function setCurrentUser(currentUser: CurrentUser): CurrentUserActions {
	return {
		type: 'SET_CURRENT_USER',
		currentUser
	}
}

export function removeCurrentUser(): CurrentUserActions {
	return {
		type: 'REMOVE_CURRENT_USER'
	}
}

export function setProjects(projects: Project[]): CurrentUserActions {
	return {
		type: 'SET_PROJECTS',
		projects
	}
}

export function setCountries(countries: Country[]): CurrentUserActions {
	return {
		type: 'SET_COUNTRIES',
		countries
	}
}

export function setSelectedProject(project: Project): CurrentUserActions {
	return {
		type: 'SET_SELECTED_PROJECT',
		project
	}
}
