const dynamicFieldsKey = 'dynamic-fields:selected';

function storeDynamicFields(fields: string[]): void {
  localStorage.setItem(dynamicFieldsKey, JSON.stringify(fields));
}

function getFields(): string[] {
  const data = localStorage.getItem(dynamicFieldsKey);

  if (!data) {
    return [];
  }

  return JSON.parse(data);
}

function clear(): void {
  localStorage.removeItem(dynamicFieldsKey);
}

export const dynamicFieldsCacheStore = {
  storeDynamicFields,
  getFields,
  clear
}
