import { Add } from '@material-ui/icons';
import { Fab, makeStyles } from '@material-ui/core';

type Props = { onClick: () => void }

const useStyles = makeStyles({
  add: {
    position: 'absolute',
    right: '60px',
    bottom: '60px'
  }
})

function AbsoluteAddButton({ onClick }: Props) {
  const classes = useStyles();

  return (
    <Fab onClick={onClick} color="primary" className={classes.add}>
      <Add fontSize="large"/>
    </Fab>
  );
}

export default AbsoluteAddButton;
