import React, { useState } from 'react';
import FilterWrapper from '../common/FilterWrapper';
import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { DatePicker } from '@material-ui/pickers';
import { Check } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectors } from '../../store/root-state';
import { BroadcastsFilterInput } from '../../utils/api/broadcasts.types';

type Props = {
  open: boolean,
  onAccept: (f: BroadcastsFilterInput) => void,
  backdropClick: () => void
}

const useStyles = makeStyles({
  grid: {
    display: 'grid',
    gridGap: '1rem'
  }
});

function BroadcastsFilter({ onAccept, open, backdropClick }: Props) {
  const classes = useStyles();
  const staticDateAdapter = new DateFnsAdapter({ locale: ruLocale });
  const countries = useSelector(selectors.countries);

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [countryId, setCountryId] = useState('');
  const [createdFrom, setCreatedFrom] = useState(null as Date | null);
  const [createdTo, setCreatedTo] = useState(null as Date | null);

  const reset = () => {
    setTitle('');
    setText('');
    setCountryId('');
    setCreatedFrom(null);
    setCreatedTo(null);
  }

  const accept = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onAccept({
      title,
      text,
      countryId,
      createdFrom: createdFrom?.toISOString(),
      createdTo: createdTo?.toISOString()
    });
  }

  return (
    <FilterWrapper backdropClick={backdropClick} open={open}>
      <form onSubmit={accept} className={classes.grid}>
        <TextField value={title}
                   onChange={e => setTitle(e.target.value as string)}
                   label="Название"
                   variant="outlined"
        />
        <TextField value={text}
                   onChange={e => setText(e.target.value as string)}
                   label="Текст сообщения"
                   variant="outlined"
        />

        <FormControl variant="outlined">
          <InputLabel id="country-label">Страна</InputLabel>
          <Select labelId="country-label"
                  value={countryId}
                  label="Страна"
                  onChange={e => setCountryId(e.target.value as string)}>
            {countries.map(c => (<MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>))}
          </Select>
        </FormControl>

        <div className={classes.grid} style={{ gridAutoFlow: 'column' }}>
          <DatePicker value={createdFrom}
                      dateAdapter={staticDateAdapter}
                      onChange={(e: Date | null) => setCreatedFrom(e)}
                      renderInput={(props: any) => <TextField {...props} variant="outlined" label="Создан с"/>}
          />

          <DatePicker value={createdTo}
                      dateAdapter={staticDateAdapter}
                      onChange={(e: Date | null) => setCreatedTo(e)}
                      renderInput={(props: any) => <TextField {...props} variant="outlined" label="Создан по"/>}
          />
        </div>

        <div className={classes.grid} style={{ gridAutoFlow: 'column', justifyContent: 'center' }}>
          <Button variant="outlined" color="secondary" onClick={reset}>Сбросить</Button>

          <Button variant="contained" color="primary"
                  startIcon={<Check/>}
                  type="submit">
            Принять
          </Button>
        </div>
      </form>
    </FilterWrapper>
  );
}

export default BroadcastsFilter;
