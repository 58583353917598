import { PaginatedData, Pagination } from './common.types';
import axios from 'axios';
import { paramsToString } from '../functions';
import { ConversationOutput, GetConversationsFilters, MessageOutput, SendMessageInput } from './messages.types';

function getConversation(id: string): Promise<ConversationOutput> {
  return axios.get(`/conversations/${id}`)
    .then(it => it.data);
}

function getConversations(filters: GetConversationsFilters): Promise<PaginatedData<ConversationOutput>> {
  return axios
    .get(`/conversations?${paramsToString(filters)}`)
    .then(it => it.data);
}

function getMessages(conversationId: string, pagination: Pagination): Promise<PaginatedData<MessageOutput>> {
  return axios
    .get(`/conversations/${conversationId}/messages?${paramsToString(pagination)}`)
    .then(it => it.data);
}

function sendMessage(data: SendMessageInput): Promise<ConversationOutput> {
  return axios.post('/messages/v2', data).then(it => it.data);
}

function sendMessageToConversation(conversationId: string, content: string): Promise<void> {
  return axios.post(`/conversations/${conversationId}/messages`, { content });
}

function getDynamicKeys(): Promise<string[]> {
  return axios.get('/messages/dynamic-keys').then(it => it.data);
}

export const messagesApi = {
  getConversation,
  getConversations,
  getMessages,
  sendMessage,
  sendMessageToConversation,
  getDynamicKeys
};
