import { GoipHttpApiChannelOutput } from '../../../../utils/api/channels.types';
import { AccountCircleOutlined, LockOutlined, SimCardOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import IndividualEditableFields from '../editable/IndividualEditableFields';
import { channelsApi } from '../../../../utils/api/channels';
import { BaseDetailProps } from '../editable/EditableFields';

type Props = BaseDetailProps & {
  channel?: GoipHttpApiChannelOutput
}

function GoipHttpDetails({ channel, acceptFn, emitter, editMode }: Props) {
  const [username, setUsername] = useState(channel?.username || '');
  const [password, setPassword] = useState(channel?.password || '');
  const [simNumber, setSimNumber] = useState(channel?.simNumber || '');

  const [details, setDetails] = useState([
    { primary: username, secondary: 'Имя пользователя', icon: <AccountCircleOutlined/>, setValue: setUsername },
    { primary: password, secondary: 'Пароль', icon: <LockOutlined/>, visibleMode: true, setValue: setPassword },
    { primary: simNumber, secondary: 'Номер сим-карты', icon: <SimCardOutlined/>, setValue: setSimNumber }
  ]);

  useEffect(() => {
    const accept = () => {
      const request = (input: any) => !channel
        ? channelsApi.createGoipHttpChannel(input)
        // todo: Заменить заглушку на метод api
        : new Promise(() => console.log(input));

      acceptFn({ username, password, simNumber }, request);
    };

    emitter.addListener('accept', accept);

    return () => {
      emitter.removeListener('accept', accept);
    }
  });

  useEffect(() => {
    const reset = () => {
      setUsername(channel?.username || '');
      setPassword(channel?.password || '');
      setSimNumber(channel?.simNumber || '');
    };

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  })

  useEffect(() => {
    setDetails([
      { ...details[0], primary: username },
      { ...details[1], primary: password },
      { ...details[2], primary: simNumber }
    ]);
  }, [username, password, simNumber]);

  return (
    <IndividualEditableFields editMode={editMode} details={details}/>
  );
}

export default GoipHttpDetails;
