import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';

type Props = { children?: React.ReactNode, variant: 'left' | 'center' | 'right' };

const useStyles = makeStyles({
  textLabel: {
    display: 'grid',
    gridTemplateColumns: (props: { columns: string }) => props.columns,
    gridGap: '10px',
    alignItems: 'center'
  }
});

function TextDivider({ variant, children }: Props) {
  let columns;

  switch (variant) {
    case 'left':
      columns = '10px max-content 1fr';
      break;
    case 'center':
      columns = '1fr max-content 1fr';
      break;
    case 'right':
      columns = '1fr max-content 10px';
      break;

  }

  const classes = useStyles({ columns });

  return (
    <div className={classes.textLabel}>
      <Divider/>
      <span style={{ display: 'flex', alignItems: 'center' }}>{children}</span>
      <Divider/>
    </div>
  );
}

export default TextDivider;
