import { Button, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Check } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css';
import { useSelector } from 'react-redux';
import { selectors } from '../../../store/root-state';
import FilterWrapper from '../../common/FilterWrapper';
import { MessengerType } from '../../../utils/api/messages.types';

type Props = {
  open: boolean,
  backdropClick: () => void,
  type: MessengerType | '',
  contact: string,
  setType: (type: MessengerType | '') => void,
  setContact: (contact: string) => void,
  onAccept: () => void
};

const useStyles = makeStyles({
  formGrid: {
    display: 'grid',
    gridGap: '1rem',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    gridGap: '10px',
    justifyContent: 'center'
  }
});

function ConversationsFilter({ open, backdropClick, setContact, setType, contact, type, onAccept }: Props) {
  const classes = useStyles();
  const countriesCodes = useSelector(selectors.countries).map(c => c.isoCode.toLowerCase());

  const cancel = () => {
    setContact('');
    setType('');
  }

  return (
    <FilterWrapper open={open} backdropClick={backdropClick}>
      <div className={classes.formGrid}>
        <PhoneInput onlyCountries={countriesCodes}
                    onEnterKeyPress={onAccept}
                    country="ru"
                    onChange={p => setContact(p)}
                    value={contact}
                    specialLabel="Номер получателя"
        />

        <FormControl variant="outlined">
          <InputLabel id="type">Тип мессенджера</InputLabel>
          <Select onChange={e => setType(e.target.value as MessengerType)} value={type} label="Тип мессенджера"
                  labelId="type">
            <MenuItem value="">---</MenuItem>
            <MenuItem value="Sms">Sms</MenuItem>
            <MenuItem value="WhatsApp">WhatsApp</MenuItem>
          </Select>
        </FormControl>

        <div className={classes.buttons}>
          <Button onClick={cancel}
                  size="small"
                  variant="outlined"
                  color="secondary">
            Сбросить
          </Button>
          <Button onClick={onAccept}
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<Check/>}
                  disableElevation>
            Принять
          </Button>
        </div>
      </div>
    </FilterWrapper>
  );
}

export default ConversationsFilter;
