import { LinearProgress, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import ConversationsFilter from './ConversationsFilter';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { selectors } from '../../../store/root-state';
import ConversationsList from './ConversationsList';
import FilterButton from '../../common/FilterButton';
import { useHistory } from 'react-router-dom';
import { messagesApi } from '../../../utils/api/messages';
import { ConversationOutput, GetConversationsFilters, MessengerType } from '../../../utils/api/messages.types';
import StyledInfiniteScroll from '../../common/StyledInfiniteScroll';
import { Settings } from '@material-ui/icons';
import DynamicFieldsSettings from './DynamicFieldsSettings';

type Props = { beforeConversationsRequest: () => void, setConversation: (value: ConversationOutput) => void }

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    height: 'inherit'
  },
  buttonsGroup: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-99%, -1px)',
    display: 'grid'
  }
});

function ConversationsWrapper({ beforeConversationsRequest, setConversation }: Props) {
  const classes = useStyles();
  const limit = 25;
  const history = useHistory();

  const selectedProject = useSelector(selectors.selectedProject);

  const [filterOpen, setFilterOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [loading, toggleLoading] = useToggle(false);
  const [conversations, setConversations] = useState(Array<ConversationOutput>());
  const [totalCount, setTotalCount] = useState(0);

  const [contact, setContact] = useState('');
  const [type, setType] = useState('' as MessengerType | '');

  const getInitialConversations = useCallback((filters: GetConversationsFilters) => {
    toggleLoading();
    beforeConversationsRequest();

    messagesApi.getConversations(filters)
      .then(c => {
        setConversations(c.data);
        setTotalCount(c.total);
      })
      .then(() => toggleLoading())
  }, [])

  const getMoreConversations = useCallback(() => {
    const filters: GetConversationsFilters = {
      messengerType: type || undefined,
      contact: contact || undefined,
      limit,
      offset: conversations.length
    }

    messagesApi.getConversations(filters)
      .then(c => setConversations(conversations.concat(c.data)))
  }, [type, contact, conversations])

  const onAccept = useCallback(() => {
    const filters: GetConversationsFilters = {
      messengerType: type || undefined,
      contact: contact || undefined,
      limit,
      offset: 0
    }

    getInitialConversations(filters);
    setFilterOpen(false);
  }, [type, contact, getInitialConversations]);

  const clickFilter = () => {
    setFilterOpen(!filterOpen);
    setSettingsOpen(false);
  }

  const clickSettings = () => {
    setSettingsOpen(!settingsOpen);
    setFilterOpen(false);
  }

  const getButtonBackground = (type: 'filter' | 'settings') => {
    switch (type) {
      case 'filter':
        return filterOpen ? 'white' : 'linear-gradient(to right, white, white, aliceblue)';

      case 'settings':
        return settingsOpen ? 'white' : 'linear-gradient(to right, white, white, aliceblue)';
    }
  }

  useEffect(() => getInitialConversations({
    messengerType: type || undefined,
    contact: contact || undefined,
    limit
  }), [selectedProject]);

  useEffect(() => {
    const parts = history.location.pathname.split('/');
    const id = parts[parts.length - 1];

    const conversation = conversations.find(c => c.id === id);

    if (conversation) {
      setConversation(conversation);
    } else {
      messagesApi.getConversation(id).then(setConversation)
    }
  }, [history])

  return (
    <div className={classes.wrapper}>
      <ConversationsFilter backdropClick={() => setFilterOpen(false)}
                           type={type}
                           contact={contact}
                           setContact={setContact}
                           setType={setType}
                           onAccept={onAccept}
                           open={filterOpen}
      />

      <DynamicFieldsSettings open={settingsOpen} backdropClick={() => setSettingsOpen(false)}/>

      {!loading
        ? <StyledInfiniteScroll more={getMoreConversations} dataLength={conversations.length} total={totalCount}>
          <ConversationsList conversations={conversations} onConversationChanged={setConversation}/>
        </StyledInfiniteScroll>
        : <LinearProgress style={{ display: 'grid' }} color="primary"/>
      }

      <div className={classes.buttonsGroup}>
        <FilterButton onClick={clickFilter} backgroundColor={getButtonBackground('filter')}
                      notAbsolute={true}/>
        <FilterButton onClick={clickSettings} backgroundColor={getButtonBackground('settings')}
                      notAbsolute={true}>
          <Settings/>
        </FilterButton>
      </div>
    </div>
  )
}

export default ConversationsWrapper;
