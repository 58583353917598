import { Badge } from "@material-ui/core";
import React from "react";

type Props = {
  children: React.ReactElement,
  vertical?: 'top' | 'bottom',
  horizontal?: 'left' | 'right',
  color?: 'primary' | 'secondary' | 'default' | 'error'
}

function ComingSoonBadge(props: Props) {
  return (
    <Badge
      children={props.children}
      badgeContent={<>Soon</>}
      anchorOrigin={{
        vertical: props.vertical ?? 'top',
        horizontal: props.horizontal ?? 'right'
      }}
      color={props.color ?? 'error'}
    />
  );
}

export default ComingSoonBadge;
