import axios from 'axios';
import { userCacheStorage } from './cache/user-cache-storage';
import { store } from '../index';
import { setError } from '../store/errors/actions';

const API_HOST = 'https://messenger-api.com';

const regex = new RegExp('^/');

export function enableInterceptors() {
	axios.interceptors.request.use(async config => {
		const user = userCacheStorage.getUser();
		const projectId = store.getState().currentUser.selectedProject.id;

		if (user) {
			config.headers['Authorization'] = `Bearer ${user.accessToken}`;
			config.headers['X-Project-Id'] = projectId || '';
		}

		config.url = `${API_HOST}/${config.url?.replace(regex, '')}`;

		return config;
	});


	axios.interceptors.response.use(it => it, error => {
		if (hasResponse(error)) {
			if (error.response.status === 401) { // todo: add refresh token (when it would be implemented on backend side)
				userCacheStorage.clear();
				window.location.reload();

				return error;
			}

			if (isHandledErrorResponse(error.response.data)) {
				store.dispatch(setError(error.response.data))
			}
		}

		return error;
	});
}

function hasResponse(it: any): it is { response: { data: any, status: number } } {
	return it?.response?.status
		&& typeof it.response.status === 'number';
}

function isHandledErrorResponse(it: any): it is { type: string, message: string } {
	return it?.type && it?.message
		&& typeof it.type === 'string'
		&& typeof it.message === 'string';
}
