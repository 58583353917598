import { Button as MaterialButton, styled } from '@material-ui/core';
import { Link as ReactLink } from 'react-router-dom'
import React from 'react';

type Props = { children?: React.ReactNode, to: string, disabled?: boolean, onClick?: (e?: React.MouseEvent) => any };

const Link = styled(ReactLink)({
  color: 'inherit',
  fontWeight: 'bolder',
  textDecoration: 'none',

  '& hover': {
    textDecoration:'none'
  }
});

const Button = styled(MaterialButton)({
  color: 'inherit'
});

function StyledLink({ children, to, disabled, onClick }: Props) {
  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) {
      return;
    }

    onClick(e);
  }

  return (
    <Link to={to} onClick={handleClick}>
      <Button disabled={disabled}>{children}</Button>
    </Link>
  );
}

export default StyledLink;
