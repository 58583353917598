import { AppBar, Divider, makeStyles, Toolbar } from '@material-ui/core';
import StyledLink from '../common/StyledLink';
import { ExitToApp } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { userCacheStorage } from '../../utils/cache/user-cache-storage';
import { removeCurrentUser } from '../../store/current-user/actions';
import Logo from '../../messenger_logo.svg';
import ProjectSelector from './ProjectSelector';
import { projectCacheStorage } from '../../utils/cache/project-cache-storage';
import RequiredProjectLink from './RequiredProjectLink';

const useStyles = makeStyles({
  toolbar: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr 1fr'
  },

  centred: {
    justifySelf: 'center',
    display: 'flex',
    gridGap: '20px',
    gridColumnStart: '2'
  },

  end: {
    display: 'flex',
    gridGap: '10px',
    justifySelf: 'end',
    gridColumnStart: '3'
  },

  logo: {
    maxHeight: '24px',
    width: 'fit-content'
  }
});

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const exitFromAccount = useCallback(() => {
    userCacheStorage.clear();
    projectCacheStorage.clear();
    dispatch(removeCurrentUser())
  }, [dispatch]);

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <img className={classes.logo} src={Logo} alt="Logo"/>

        <div className={classes.centred}>
          <StyledLink to="/message-statistic">Статистика</StyledLink>
          <RequiredProjectLink to="/channels">Каналы</RequiredProjectLink>
          <RequiredProjectLink to="/broadcast">Массовая рассылка</RequiredProjectLink>
          <RequiredProjectLink to="/chats">Чаты</RequiredProjectLink>
        </div>

        <div className={classes.end}>
          <Divider orientation="vertical" flexItem/>
          <ProjectSelector/>
          <Divider orientation="vertical" flexItem/>

          <StyledLink to="/auth" onClick={exitFromAccount}>
            <ExitToApp style={{ marginRight: '10px' }}/>Выйти
          </StyledLink>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
