import { MessageStatisticActions, MessageStatisticState } from './types';
import { Statistic } from '../../utils/api/statistic.types';

export function setMessageStatistic(stats: Statistic.MessageStatisticOutput): MessageStatisticActions {
	return {
		type: 'SET_MESSAGE_STATISTIC',
		stats
	};
}

export function setMessageStatisticFilters(filters: MessageStatisticState['filters']): MessageStatisticActions {
	return {
		type: 'SET_MESSAGE_STATISTIC_FILTERS',
		filters
	};
}

export function startMessageStatisticLoading(): MessageStatisticActions {
	return {
		type: 'SET_MESSAGE_STATISTIC_IS_LOADING',
		value: true
	}
}

export function completeMessageStatisticLoading(): MessageStatisticActions {
	return {
		type: 'SET_MESSAGE_STATISTIC_IS_LOADING',
		value: false
	}
}
