import { Backdrop, makeStyles, Paper, Slide } from '@material-ui/core';
import React from 'react';

type Props = {
  open: boolean,
  backdropClick: () => void,
  children?: React.ReactNode
};

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    padding: '20px'
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1,
    overflow: 'hidden'
  }
});

function FilterWrapper({ open, backdropClick, children }: Props) {
  const classes = useStyles();

  return (
    <Backdrop onClick={backdropClick} className={classes.backdrop} open={open}>
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Paper className={classes.paper} elevation={0} square onClick={e => e.stopPropagation()}>
          {children}
        </Paper>
      </Slide>
    </Backdrop>
  )
}

export default FilterWrapper;
