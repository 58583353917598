import React from 'react';
import EditableField from './EditableField';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../store/clipboard/actions';

export type DetailsData = DetailData[];

export type DetailData = {
  primary: string,
  secondary: string,
  icon?: React.ReactNode,
  notCopyable?: boolean,
  visibleMode?: boolean,
  setValue?: (value: any) => void
};

type Props = { details: DetailsData, editMode: boolean };

function IndividualEditableFields({ details, editMode }: Props) {
  const dispatch = useDispatch();

  const copy = (text: string) =>
    navigator.clipboard.writeText(text)
      .then(() => dispatch(setSnackbar({ open: true, text: 'Текст скопирован' })));

  return (
    <>
      {details.map((detail, i) => (
        <EditableField key={i} editMode={editMode} detail={detail} onCopy={copy}/>
      ))}
    </>
  )
}

export default IndividualEditableFields;
