import { YeastarChannelOutput } from '../../../../utils/api/channels.types';
import {
  AccountCircleOutlined,
  CallMadeOutlined,
  CallReceivedOutlined,
  DeviceHub,
  LinearScale,
  LockOutlined
} from '@material-ui/icons';
import IndividualEditableFields, { DetailsData } from '../editable/IndividualEditableFields';
import { useEffect, useState } from 'react';
import { channelsApi } from '../../../../utils/api/channels';
import { BaseDetailProps } from '../editable/EditableFields';

type Props = BaseDetailProps & {
  channel?: YeastarChannelOutput
};

function YeastarDetails({ channel, emitter, acceptFn, editMode }: Props) {
  const [account, setAccount] = useState(channel?.account || '');
  const [password, setPassword] = useState(channel?.password || '');
  const [line, setLine] = useState(channel?.line || 0);
  const [host, setHost] = useState(channel?.host || '');
  const [incomingPort, setIncomingPort] = useState(channel?.incomingPort || 0);
  const [outgoingPort, setOutgoingPort] = useState(channel?.outgoingPort || 0);

  const [details, setDetails] = useState<DetailsData>([
    { primary: account, secondary: 'Аккаунт', icon: <AccountCircleOutlined/>, setValue: setAccount },
    { primary: password, secondary: 'Пароль', icon: <LockOutlined/>, setValue: setPassword, visibleMode: true },
    { primary: line.toString(), secondary: 'Номер канала', icon: <LinearScale/>, setValue: setLine },
    { primary: host, secondary: 'Хост', icon: <DeviceHub/>, setValue: setHost },
    {
      primary: incomingPort.toString(),
      secondary: 'Входящий порт',
      icon: <CallReceivedOutlined/>,
      setValue: setIncomingPort
    },
    {
      primary: outgoingPort.toString(),
      secondary: 'Исходящий порт',
      icon: <CallMadeOutlined/>,
      setValue: setOutgoingPort
    }
  ]);

  useEffect(() => {
    const accept = () => {
      const request = (input: any) => !channel
        ? channelsApi.createYeastarChannel(input)
        : new Promise(() => console.log('edit', input));

      acceptFn({ account, password, line, host, incomingPort, outgoingPort }, request);
    };

    emitter.addListener('accept', accept);

    return () => {
      emitter.removeListener('accept', accept);
    }
  });

  useEffect(() => {
    const reset = () => {
      setAccount(channel?.account || '');
      setPassword(channel?.password || '');
      setLine(channel?.line || 0);
      setHost(channel?.host || '');
      setIncomingPort(channel?.incomingPort || 0);
      setOutgoingPort(channel?.outgoingPort || 0);
    };

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  });

  useEffect(() => {
    setDetails([
      { ...details[0], primary: account },
      { ...details[1], primary: password },
      { ...details[2], primary: line.toString() },
      { ...details[3], primary: host },
      { ...details[4], primary: incomingPort.toString() },
      { ...details[5], primary: outgoingPort.toString() }
    ]);
  }, [account, password, line, host, incomingPort, outgoingPort]);

  return (
    <IndividualEditableFields editMode={editMode} details={details}/>
  )
}

export default YeastarDetails;
