import { ErrorsActions, ErrorsState } from './types';

const initialState: ErrorsState = {
  error: { type: '', message: '' }
}

export function errorsReducers(
  state = initialState,
  action: ErrorsActions
): ErrorsState {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        error: action.error
      };

    default:
      return state;
  }
}
