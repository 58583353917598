import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../../utils/functions';
import { AccessTime, DoneAll, ErrorOutline, FileCopy } from '@material-ui/icons';
import { MessageOutput } from '../../../utils/api/messages.types';
import TextDivider from '../../common/TextDivider';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../store/clipboard/actions';
import { dynamicFieldsCacheStore } from '../../../utils/cache/dynamic-fields-cache-storage';

type Props = { message: MessageOutput, order: number };
type MessageProps = { message: MessageOutput, copy: (text: string) => void, fixedFields: string[] };

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    order: (props?: { order?: number }) => props?.order || 0
  },
  messageWrapper: {
    display: 'grid',
    gridGap: '10px',
    transition: '.2s',
    transform: (props: { offset?: number }) => `translateX(${props.offset}px)`,

    '& .data': {
      maxHeight: 0,
      transition: '.2s',
      overflowY: 'auto'
    },

    '&:hover': {
      transform: 'translateX(0)'
    },

    '&:hover .data': {
      maxHeight: '200px'
    }
  },
  messageGrid: {
    gridTemplateColumns: '1fr 20px',
    gridAutoRows: '1fr 20px',
    padding: '10px',
    fontSize: '1.2rem',
    maxWidth: '80%',
    minWidth: '150px',
    gridTemplateAreas: '\'content .\' ' +
      '                 \'date state\''
  },
  content: {
    gridArea: 'content'
  },
  state: {
    alignSelf: 'center',
    justifySelf: 'end',
    gridArea: 'state',
    fontSize: '14px'
  },
  date: {
    fontSize: '12px',
    color: theme.palette.grey.A200,
    gridArea: 'date',
    alignSelf: 'center'
  },
  incomingMessage: {
    justifySelf: 'start',
    backgroundColor: 'lightyellow',
    borderRadius: '10px',
    borderBottomLeftRadius: 'inherit'
  },
  outgoingMessage: {
    justifySelf: 'end',
    backgroundColor: 'aliceblue',
    borderRadius: '10px',
    borderBottomRightRadius: 'inherit'
  }
}));

function MessageView({ message, order }: Props) {
  const classes = useStyles({ order });
  const dispatch = useDispatch();
  const fields = dynamicFieldsCacheStore.getFields();

  const copy = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => dispatch(setSnackbar({ open: true, text: 'Текст скопирован' })))
  }

  return (
    <div className={classes.wrapper}>
      {message.type === 'Incoming'
        ? <IncomingMessage fixedFields={fields} copy={copy} message={message}/>
        : <OutgoingMessage fixedFields={fields} copy={copy} message={message}/>
      }
    </div>
  );
}

function DynamicKeysView({ message, copy, fixedFields }: Exclude<MessageProps, ''>) {
  const dynamicKeys = !!message?.dynamicData
    ? Object.keys(message.dynamicData).filter(d => !fixedFields.includes(d))
    : [];

  if (!dynamicKeys.length) {
    return <Typography variant="h5">Нет данных</Typography>;
  }

  return (
    <>
      <TextDivider variant="center">Доп. данные</TextDivider>
      <List style={{ padding: '0 5px' }} dense>
        {dynamicKeys.map(key => {
          const data = message.dynamicData[key];
          const value = data ? data : <span style={{ color: 'red' }}>Null</span>;

          return (
            <ListItem key={key}>
              <ListItemText primary={value} secondary={key} key={key}/>
              {data &&
              <ListItemSecondaryAction>
                  <IconButton onClick={() => copy(data)} color="primary">
                      <FileCopy/>
                  </IconButton>
              </ListItemSecondaryAction>
              }
            </ListItem>
          )
        })}
      </List>
    </>
  );
}

function OutgoingMessage({ message, copy, fixedFields }: MessageProps) {
  const classes = useStyles({ offset: 310 });

  return (
    <div className={classes.messageWrapper} style={{ gridTemplateColumns: '1fr 300px' }}>
      <Paper variant="outlined"
             className={`${classes.outgoingMessage} ${classes.messageGrid}`}>
        <div className={classes.content}>
          {message.content}
        </div>

        <div className={classes.date}>
          {formatDate(message.sentAt)}
        </div>

        {fixedFields.map(f => {
          const data = message.dynamicData[f];

          return (
            <>
              {data && <div style={{ fontSize: '12px' }}>{f} - {data}</div>}
            </>
          )
        })}
      </Paper>

      <Paper className="data" variant="outlined">
        <DynamicKeysView message={message} fixedFields={fixedFields} copy={copy}/>
      </Paper>
    </div>
  );
}

function IncomingMessage({ message, copy, fixedFields }: MessageProps) {
  const classes = useStyles({ offset: -310 });

  const getStateIcon = (state: MessageOutput['state']) => {
    switch (state) {
      case 'Pending':
        return <AccessTime/>;

      case 'Sent':
      case 'Read':
        return <DoneAll/>;

      case 'Error':
        return <ErrorOutline/>;
    }
  }

  return (
    <div className={classes.messageWrapper} style={{ gridTemplateColumns: '300px 1fr' }}>
      <Paper className="data" variant="outlined">
        <DynamicKeysView message={message} fixedFields={fixedFields} copy={copy}/>
      </Paper>

      <Paper variant="outlined"
             className={`${classes.incomingMessage} ${classes.messageGrid}`}>
        <div className={classes.content}>
          {message.content}
        </div>

        <div className={classes.date}>
          {formatDate(message.sentAt)}
        </div>

        <div className={classes.state}>{getStateIcon(message.state)}</div>

        {fixedFields.map(f => {
          const data = message.dynamicData[f];

          return (
            <>
              {data && <div style={{ fontSize: '12px' }}>{f} - {data}</div>}
            </>
          )
        })}
      </Paper>
    </div>
  );
}

export default MessageView;
