import { TwilioChannelOutput } from '../../../../utils/api/channels.types';
import { LockOutlined, SimCardOutlined, VpnKeyOutlined } from '@material-ui/icons';
import IndividualEditableFields, { DetailsData } from '../editable/IndividualEditableFields';
import { useEffect, useState } from 'react';
import { channelsApi } from '../../../../utils/api/channels';
import { BaseDetailProps } from '../editable/EditableFields';

type Props = BaseDetailProps & {
  channel?: TwilioChannelOutput
};

function TwilioDetails({ channel, emitter, acceptFn, editMode }: Props) {
  const [accountSid, setAccountSid] = useState(channel?.accountSid || '');
  const [simNumber, setSimNumber] = useState(channel?.simNumber || '');
  const [token, setToken] = useState(channel?.token || '');

  const [details, setDetails] = useState<DetailsData>([
    { primary: accountSid, secondary: 'Account sid', icon: <LockOutlined/>, setValue: setAccountSid },
    { primary: simNumber, secondary: 'Номер сим-карты', icon: <SimCardOutlined/>, setValue: setSimNumber },
    { primary: token, secondary: 'Токен', icon: <VpnKeyOutlined/>, setValue: setToken }
  ]);

  useEffect(() => {
    const accept = () => {
      const request = (input: any) => !channel
        ? channelsApi.createTwilioChannel(input)
        : new Promise(() => console.log('edit', input));

      acceptFn({ accountSid, simNumber, token }, request);
    };

    emitter.addListener('accept', accept);

    return () => {
      emitter.removeListener('accept', accept);
    }
  });

  useEffect(() => {
    const reset = () => {
      setAccountSid(channel?.accountSid || '');
      setSimNumber(channel?.simNumber || '');
      setToken(channel?.token || '');
      console.log('123');
    };

    emitter.addListener('reset', reset);

    return () => {
      emitter.removeListener('reset', reset);
    }
  });

  useEffect(() => {
    setDetails([
      { ...details[0], primary: accountSid },
      { ...details[1], primary: simNumber },
      { ...details[2], primary: token }
    ]);
  }, [accountSid, simNumber, token]);

  return (
    <IndividualEditableFields editMode={editMode} details={details}/>
  );
}

export default TwilioDetails;
