import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Auth from './components/Auth';
import AppRoutes from './components/AppRoutes';
import { userApi } from './utils/api/user';
import { setCountries, setCurrentUser, setProjects } from './store/current-user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from './store/root-state';
import { userCacheStorage } from './utils/cache/user-cache-storage';
import ErrorHandler from './components/ErrorHandler';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { setSnackbar } from './store/clipboard/actions';

// const BASE_PATH = process.env.PUBLIC_URL;

function App() {
	const user = useSelector(selectors.currentUser);
	const dispatch = useDispatch();
	const cachedUser = userCacheStorage.getUser();
	const clipboard = useSelector(selectors.snackbar);

	useEffect(() => {
		cachedUser && dispatch(setCurrentUser(cachedUser));
	}, [])

	useEffect(() => {
		if (!user) {
			return;
		}

		userApi.getProjects().then(it => dispatch(setProjects(it.data)))
		userApi.getCountries().then(it => dispatch(setCountries(it.data)))
	}, [user])

	return (
		<Router>
			{!user && !cachedUser && <Redirect exact to="/auth"/>}

			<Switch>
				<Route exact path="/auth">
					<Auth/>
				</Route>

				<AppRoutes/>
			</Switch>

			<ErrorHandler/>

			<Snackbar open={clipboard.open}
								autoHideDuration={3000}
								onClose={() => dispatch(setSnackbar({ open: false }))}>
				<Alert severity="info"
							 icon={false}
							 onClose={() => dispatch(setSnackbar({ open: false }))}>
					{clipboard.text}
				</Alert>
			</Snackbar>
		</Router>
	);
}

export default App;
