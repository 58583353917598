import { BaseDetailProps } from "../editable/EditableFields";
import { SmsCountryChannelOutput } from "../../../../utils/api/channels.types";
import { useEffect, useState } from "react";
import { channelsApi } from "../../../../utils/api/channels";
import { AccountCircleOutlined, LockOutlined } from "@material-ui/icons";
import IndividualEditableFields from "../editable/IndividualEditableFields";

type Props = BaseDetailProps & {
	channel?: SmsCountryChannelOutput
};

function SmsCountryDetails({ channel, emitter, acceptFn, editMode }: Props) {
	const [username, setUsername] = useState(channel?.username ?? '');
	const [password, setPassword] = useState(channel?.password ?? '');

	const [details, setDetails] = useState([
		{ primary: username, secondary: 'Логин', icon: <AccountCircleOutlined/>, setValue: setUsername },
		{ primary: password, secondary: 'Пароль', icon: <LockOutlined/>, visibleMode: true, setValue: setPassword },
	]);

	useEffect(() => {
		const accept = () => {
			const request = (input: any) => !channel
				? channelsApi.createSmgHttpChannel(input)
				: new Promise(() => console.log('create', input));

			acceptFn({ login: username, password }, request);
		};

		emitter.addListener('accept', accept);

		return () => {
			emitter.removeListener('accept', accept);
		}
	});

	useEffect(() => {
		const reset = () => {
			setUsername(channel?.username ?? '');
			setPassword(channel?.password ?? '');
		};

		emitter.addListener('reset', reset);

		return () => {
			emitter.removeListener('reset', reset);
		}
	});

	useEffect(() => {
		setDetails([
			{ ...details[2], primary: username },
			{ ...details[3], primary: password }
		])
	}, [username, password]);

	return (
		<IndividualEditableFields editMode={editMode} details={details}/>
	);
}

export default SmsCountryDetails;
