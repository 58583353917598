import { MessagesFilters } from './messages.types';
import axios from 'axios';
import { paramsToString } from '../functions';
import { Statistic } from './statistic.types';

function getMessagesStatistic(filters: MessagesFilters): Promise<Statistic.MessageStatisticOutput> {
  return axios
    .get(`/messages/statistics/count?${paramsToString(filters)}`)
    .then(it => it.data);
}

export const statisticApi = {
  getMessagesStatistic
}
