import { Button, makeStyles } from '@material-ui/core';
import { FileCopyOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';
import { useToggle } from 'react-use';
import { DetailData } from './IndividualEditableFields';
import EditableInput from './EditableInput';

type Props = {
  detail: DetailData,
  onCopy?: (text: string) => void,
  editMode: boolean
};

const useStyles = makeStyles({
  control: {
    display: 'flex',
    alignItems: 'start',
    gridGap: '5px'
  },
  button: {
    height: '40px',
    minWidth: '40px'
  }
});

function EditableField({ detail, onCopy, editMode }: Props) {
  const classes = useStyles();
  const [visible, toggleVisible] = useToggle(!detail.visibleMode);

  return (
    <div className={classes.control}>
      <EditableInput visible={visible}
                     value={detail.primary}
                     setValue={detail.setValue}
                     icon={detail.icon}
                     label={detail.secondary}
                     editMode={editMode}
      />

      {detail.visibleMode &&
      <Button className={classes.button} color="primary" onClick={() => toggleVisible()}>
        {visible ? <VisibilityOff/> : <Visibility/>}
      </Button>
      }

      {!detail.notCopyable &&
      <Button className={classes.button} onClick={() => onCopy?.(detail.primary)} color="primary">
        <FileCopyOutlined/>
      </Button>
      }
    </div>
  );
}

export default EditableField;
