import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	FormControl,
	InputLabel,
	makeStyles,
	TextField
} from '@material-ui/core';
import { DateRange, DateRangeDelimiter, DateRangePicker } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../store/root-state';
import { setMessageStatisticFilters } from '../../store/message-statistic/actions';
import { MessageStatisticState } from '../../store/message-statistic/types';
import { ExpandMore } from '@material-ui/icons';
import QuickDateFilters from './QuickDateFilters';
import { useState } from 'react';
import WrappedSelect from '../common/WrappedSelect';

const useStyles = makeStyles({
	wrapper: {
		display: 'grid',
		justifyContent: 'center',
		gridGap: '20px',
		gridAutoColumns: 'minmax(500px, 1000px)',
		paddingBottom: '20px'
	},
	filters: {
		display: 'grid',
		gridGap: '30px',
		gridTemplateColumns: '1fr 1fr 2fr'
		// padding: '50px 15px 30px',
		// gridAutoColumns: '500px',
		// gridAutoFlow: 'column'
	},
	buttons: {
		display: 'grid',
		justifySelf: 'center',
		gridTemplateColumns: '1fr 1fr',
		// gridTemplateRows: '50px',
		gridGap: '1rem'
	}
});

const staticDateAdapter = new DateFnsAdapter({ locale: ruLocale });

function Filters() {
	const classes = useStyles();

	const dispatch = useDispatch();

	const projects = useSelector(selectors.projects);
	const countries = useSelector(selectors.countries);
	const filters = useSelector(selectors.messageStatistic.filters);
	const isLoading = useSelector(selectors.messageStatistic.isLoading);

	const [localFilter, setLocalFilter] = useState(filters);
	const [isQuickFilters, toggleQuickFilters] = useState(false);

	const setFilters = (data: Partial<MessageStatisticState['filters']>) => {
		setLocalFilter({ ...localFilter, ...data });
	}

	const setQuickFilters = (dates: DateRange<Date>) => {
		toggleQuickFilters(false);
		setFilters({ dates });
	};

	const clearFilters = () => {
		setFilters({ projectIds: [], countryIds: [], dates: [null, null] });
	}

	const applyFilters = () => {
		dispatch(setMessageStatisticFilters(localFilter));
	}

	return (
		<div className={classes.wrapper}>
			<h2 style={{ textAlign: 'center' }}>
				Фильтр
			</h2>

			<div className={classes.filters}>
				<FormControl variant="outlined">
					<InputLabel id="projects">Проекты</InputLabel>
					<WrappedSelect
						labelId='projects'
						values={projects}
						value={localFilter.projectIds}
						onChange={projectIds => setFilters({ projectIds })}
					/>
				</FormControl>

				<FormControl variant="outlined">
					<InputLabel id="countries">Страны</InputLabel>
					<WrappedSelect
						labelId='countries'
						values={countries}
						value={localFilter.countryIds}
						onChange={countryIds => setFilters({ countryIds })}
					/>
				</FormControl>

				<div>
					<DateRangePicker
						value={localFilter.dates}
						onChange={dates => setFilters({ dates })}
						calendars={1}
						allowSameDateSelection={true}
						// @ts-ignore
						dateAdapter={staticDateAdapter}
						renderInput={(startProps, endProps) => (
							<>
								<TextField {...startProps} />
								<DateRangeDelimiter> to </DateRangeDelimiter>
								<TextField {...endProps} />
							</>
						)}
					/>
				</div>
			</div>

			<div className="statistic__filters__quick">
				<Accordion
					expanded={isQuickFilters}
					onClick={() => toggleQuickFilters(!isQuickFilters)}
				>
					<AccordionSummary expandIcon={<ExpandMore/>}>
						Быстрые фильтры
					</AccordionSummary>
					<AccordionDetails style={{ display: 'block' }}>
						<QuickDateFilters onQuickFilterSelected={setQuickFilters}/>
					</AccordionDetails>
				</Accordion>
			</div>

			<div className={classes.buttons}>
				<Button variant="outlined"
								color="secondary"
								onClick={clearFilters}
				>
					Сбросить фильтры
				</Button>
				<Button disabled={isLoading}
								variant="contained"
								color="primary"
								onClick={applyFilters}
				>
					Применить
				</Button>
			</div>
		</div>
	);
}

export default Filters;
